.nowarrantyapp-background {
    padding: 4% 0% 0% 0%;
    @media (max-width: 768px) {
        padding: 0 40px;
    }
    @media only screen and (max-width: 980px) {   
        
h1 {
    margin-top: 20px;
    font-size: 20px!important;
    font-family: roboto condensed,sans-serif!important;
    font-weight: 700!important;
    letter-spacing: .2em!important;
}
.accessibilityText p {

    margin-top: 0px;
}
.tshirtForm-Discription {
    padding-bottom: 0px;
}
       
    
}
}

.nowarranty-table {
    overflow:auto;
    @media (max-width: 768px) {
        padding: 0 40px;
    }
    
    table,  th {
        border: 1px solid rgba(0,0,0,.1);
        font-weight: 700 !important;
        font-size: 12px !important;
        padding: 8px;
    }
    table,  td {
      border: 1px solid rgba(0,0,0,.1);
      font-weight: 400 !important;
      font-size: 16px !important;
      padding: 8px;
      
      margin-bottom: 5%;
  }
}



.no-warranty-main {
   padding-bottom:  5% !important;
   @media (max-width: 768px) {
    padding: 0 40px;
}

}