.app-search-page {
  .app-search-no-result {
    .app-search-header {
      font-size: 48px;
      line-height: 1.2em;
      @media only screen and (max-width: 992px) {
        font-size: 28px;
      }
    }
    .app-search-body {
      margin-top: 2em;
      .app-header-subtitle {
        line-height: 1.5em;
        color: #666;
      }
      .app-search-box {
        width: 50%;
        @media only screen and (max-width: 992px) {
          width: 80%;
        }
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
}
