.content-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2147483640;
  overflow-y: auto;
  .content-container {
    min-width: 300px;
    height: 100%;
    position: relative;
    padding: 30px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .details-container {
      background-color: #fff;
      position: relative;
      margin: 0px 5px;
      .content {
        padding: 5%;
        max-height: 90vh;
        min-width: 300px;
        min-height: 300px;
        max-width: 768px;
        background-color: #fff;
        position: relative;
        overflow-y: auto;
        h6 {
          font-size: 16px!important;
          line-height: 1.5em!important;
          color: #666;
          font-weight: 400
        };
        h2 {
          font-size: 38px !important;
          @media (max-width: 768px) {
            font-size: 20px !important;
          }
          
        }
        table,  th {
          border: 1px solid rgba(0,0,0,.1);
          font-weight: 700 !important;
          font-size: 12px !important;
          padding: 8px;
      }
      table,  td {
        border: 1px solid rgba(0,0,0,.1);
        font-weight: 400 !important;
        font-size: 12px !important;
        padding: 8px;
    }
    b, strong {      
      font-size: 12px !important; 
  }
     
        @media only screen and (min-width: 992px) {
          width: 768px;
        }
       
       
      }
    }
    .content-close-icon {
      color: white;
      font-size: 18px;
      font-weight: 600;
      position: absolute;
      right: 25px;
      top: 5px;
      cursor: pointer;
    }
  }
}
