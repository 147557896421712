.app-checkout-form {
  padding: 04% 0% 4% 0%;
  .app-logout-amazon {
    font-size: 18px;
    color: #ec2524;
    span {
      text-decoration: underline;
      color: #333;
      cursor: pointer;
      &:hover {
        color: black;
      }
    }
  }
  .app-address-loader {
    background-color: rgba(255, 255, 255, 0.603);
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .app-amazon-widget-headers {
    color: #666;
    font-size: 28px;
  }
  .checkout-h1 {
    font-size: 48px !important;
    padding-bottom: 0;
    letter-spacing: 0.05em !important;
    font-weight: 500 !important;
    line-height: 1.2em;
  }
  .checkout-usp {
    font-weight: bold;
    font-size: 1.2em;
    margin: 1em 0;
    line-height: 1.5em !important;
    color: #666;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .checkout-errorvalidationMessage {
    color: #dc3545;
  }
  .checkout-billing-details {
    font-size: 28px !important;
    font-weight: 500 !important;
    line-height: 1.2em;
    color: #666;
  }

  .checkout-Label {
    color: #666;
    font-weight: bold;
  }
  .form-control {
    margin-bottom: 10px;
  }

  .card-header {
    border-bottom: none;
    background-color: #ededed;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 700;
    color: #666;
  }
  .have-coupon {
    font-size: 1.1em;
    color: #de0000;
  }
  .have-coupon-link {
    color: black;
    text-decoration: underline;
    cursor: pointer;
    padding-left: 5px;
  }

  .custom-apply-coupon-submit {
    position: relative;
    white-space: nowrap;
    display: inline-block !important;
    margin-right: 0;
    padding: 0.5em 1em;
    border-radius: 0;
    background: black !important;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }

  .custom-coupon-disclaimer {
    display: block;
    color: #333;
    font-size: 12px;
  }

  .have-coupon-hidden {
    display: none;
  }
  .have-coupon-show {
    display: inline;
  }
  .error-message-astrid-show {
    color: red;
    margin-left: 5px;
  }
  .checkout-Form-accordion-payment {
    padding-bottom: 20px;
  }
}

.checkout-without-item {
  padding: 5% 0%;
  .checkout-without-item-h1 {
    font-size: 48px !important;
    padding-bottom: 0;
    letter-spacing: 0.05em !important;
  }
  .checkout-without-button {
    color: #ffffff;
    background-color: #666666;
    font-weight: normal;
    text-transform: none;
    font-size: inherit;
    line-height: inherit;
    padding: 10px 20px;
    border-radius: 40px !important;
  }
  .checkout-without-button:hover {
    text-decoration: none;
  }
}

@media only screen and (max-width: 1200px) {
  .app-checkout-form {
    .custom-apply-coupon-submit-postion {
      // padding-top: 35px;
    }
  }
}

// .fa-stack {
//   position: relative;
//   display: inline-block;
//   width: 2em;
//   height: 2em;
//   line-height: 2em;
//   vertical-align: middle;
// }

.orderheader a{
  color: #666 !important;
}

.checkouttooltips {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  
  padding: 5px 10px;
  border-radius: 60px;
  font-size: 14px;
  line-height: 12px;
  border: 1px solid #666;
  margin-left: 10px; 
  color: #666 !important;
}

.checkouttooltips .checkouttooltiptexts {
  visibility: hidden;
  width: 360px;
  background-color: #d3e7fb;
  color: #666;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: -45px;
  left: 150%;
  font-size: 12px;
}

.checkouttooltips .checkouttooltiptexts::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #d3e7fb transparent transparent;
}

.checkouttooltips:hover .checkouttooltiptexts {
  visibility: visible;
  opacity: 1;
}


@media only screen and (max-width: 1024px) {
 
   .checkouttooltiptexts {
     width: 280px !important;
   }
}
@media only screen and (max-width: 768px) {
 
  .checkouttooltiptexts {
    width: 170px !important;
  }
}
@media only screen and (max-width: 325px) {
 
  .checkouttooltiptexts {
    width: 125px !important;
  }
}
