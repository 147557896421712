.prod-benefits-container {
  background-color: black;
}

.prod-benefits-container-land {
  background-color: transparent;
  background-image: linear-gradient(270deg, #fc5310 0%, #5d1c07 100%);
}

.benefits-row {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.benefits-col {
  width: 50%;
}

.col-wrapper {
  padding: 30px 30px 30px 30px;
  width: 100%;
  // text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.prod-benefits-container-land .benefits-row {
  max-width: 1200px;
  margin: auto;
}

.prod-benefits-container-land .col-wrapper {
  align-items: flex-start;
  padding: 10px;
}

.title-col-name p {
  font-size: 34px;
  font-weight: 500;
  line-height: 1.25;
  color: white;
  margin-bottom: 0;
  text-align: center;
}

.col-wrapper-padding .title-col-name p {
  margin-bottom: 20px;
}

.prod-benefits-container-land .title-col-name p {
  text-align: left;
}

.col-wrapper-padding {
  padding: 60px 30px 60px 30px;
}

.prod-benefits-container-land .col-wrapper-padding {
  padding: 30px;
}

.world-champ-image-wrapper {
  text-align: center;
}

.world-champ-image-wrapper img {
  height: auto;
  max-width: 100%;
}

.col-wrapper-padding ul {
  list-style: none;
  margin: 0 15px;
  font-size: 15px;
  padding: 0;
}

.col-wrapper-padding li {
  margin: 1.5em 0;
  line-height: 1.5;
  padding-left: 2.25em;
  text-indent: -2.25em;
  color: #ffffffde;
  font-size: 15px;
}

.col-wrapper-padding li::before {
  content: "✓";
  color: white;
  background: #fc5310;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.25em;
  height: 1.25em;
  margin: 0 0.5em;
  border-radius: 0.25em;
  transform: translateY(10%);
  padding-left: 0px;
  text-indent: 0px;
}

.show-now-btn {
  display: none;
  text-align: center;
}

.world-sub-title {
  font-size: 16px;
  color: white;
  margin-bottom: 45px;
}

.social-icons-wrapper {
  display: flex;
}

.social-icons-wrapper a {
    color: white;
    text-decoration: none;
  }

.social-icons-wrapper .raw-html-embed {
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icons-wrapper svg {
    width: 2rem;
    height: 1.5rem;
  }

.social-icons-wrapper i {
  font-size: 1.6rem;
}

.display-world-image {
  display: none;
}

@media (max-width: 979px) {
  .benefits-row {
    flex-wrap: wrap;
  }
  .benefits-col {
    width: 100%;
  }
  .prod-benefits-container-land .benefits-row {
    flex-wrap: nowrap;
  }
  .col-wrapper-padding {
    padding: 30px;
  }
  .col-wrapper-padding li {
    margin-top: 0;
  }
}

@media (max-width: 980px) {
  .shop-now-btn {
    display: none !important;
  }
  .show-now-btn {
    display: block;
    margin-bottom: 1.2em;
  }
}

@media (max-width: 750px) {
  .benefits-row {
    flex-direction: row-reverse;
  }
}

@media (max-width: 480px) {
  .col-wrapper {
    padding: 15px 0px 0px 0px;
  }
  .col-wrapper-padding {
    padding: 0.5em 1em 0.5em 1em;
  }
  .title-col-name p {
    font-size: 28px;
  }
  .prod-benefits-container-land .benefits-row {
    flex-wrap: wrap;
  }
  .prod-benefits-container-land .col-wrapper {
    align-items: center;
    padding: 15px;
  }
  .prod-benefits-container-land .title-col-name p {
    text-align: center;
    width: 90%;
    margin: auto;
  }
  .world-sub-title {
    text-align: center;
  }
  .prod-benefits-container-land .col-wrapper-padding {
    padding: 20px 0;
  }
  .display-world-image {
    display: block;
  }
  .hide-world-image {
    display: none;
  }
}

.accessories-desc-ul ul {
  padding: 0;
  margin: 1.5em 0 1.5em 3em;
}