.card-img-text-container {
  .col-height {
    height: 450px;
    margin-top: 20px;
    // width: 390px;
  }
  @media only screen and (max-width: 992px) and (min-width: 562px) {
    .col-height {
      height: 800px;
      margin-top: 20px;
    }
  }
  .center-ing {
    justify-content: center;
  }
}

@media (max-width: 980px){
  .textimagestyle{
    background-color: #231f20;
  }
}
