.accessibility-background {
    padding: 40px 40px; 
    @media (max-width: 768px) {
        padding: 30px;
    }
}
.accessibilityText {
        p {
            line-height: 1.5em!important;
            color: #666 !important;
            margin-top: 2em;
            font-weight: 400;
        }

        h1 {
            font-size: 48px!important;
            padding-bottom: 0;
            letter-spacing: .05em!important;
        }
        a {
            color: #e09900;
            text-decoration: none;
        }
        @media only screen and (max-width: 980px) {
            h1 {
                font-size: 28px !important;
            }   
        }
    

}
