.app-footer {
  .footer-container {
    padding: 2% 0px 2% 8%;
    background-color: #231e1f !important;
    color: #fff !important;
    .line-wrap {
      white-space: nowrap;
      color: #fff;
    }
    .row {
      margin-right: 0px;
    }
    .mobileview {
      margin-bottom: 20px;
      display: none;
    }
    .subscribe-mobile-view {
      display: none;
    }
    @media only screen and (max-width: 768px) {
      .mobileview {
        display: block;
      }
    }
    @media only screen and (min-width: 768px) {
      .subscribe-mobile-view {
        display: block;
      }
    }
  }

  .logo-footer-container {
    display: flex;
    padding-top: 10px;
    justify-content: center;
  }

  .logo-footer-container > a {
    padding-left: 20px;
  }

  .logo-footer-container > a > img {
    width: 35px;
    height: 35px;
  }

  .center-align-footer .row {
    justify-content: center !important ;
  }

  .footer-sub-text {
    margin: 0px !important;
    font-size: 15px;
    font-weight: 300 !important;
    font-size: 13px !important;
    letter-spacing: 0.1em !important;
  }

  .footer-sub-text p a {
    text-decoration: none;
    color: #fff;
  }

  .footer-sub-text p {
    margin: 0px !important;
    height: 27px;
  }

  .footer-text-box {
    border-radius: 2px;
    padding: 0px 0px 0px 16px;
    height: 46px;
    outline: none;
    text-align: left;
    color: rgb(36, 36, 36);
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    background-color: rgb(255, 255, 255);
    width: 54%;
  }

  .subscription-success {
    p {
      padding: 0px !important;
    }
    p:first-child {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .footer-button {
    margin-left: 5px;
    padding: 11px 10px !important;
    background: rgb(224, 3, 3) !important;
    border-radius: 0px !important;
    border-style: solid !important;
    border-color: rgb(224, 3, 3);
    border-width: 3px !important;
    color: rgb(255, 255, 255) !important;
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    letter-spacing: 0px !important;
    line-height: 1 !important;
    white-space: normal !important;
    text-align: center !important;
    word-break: break-word !important;
    align-self: flex-end !important;
    cursor: pointer !important;
    position: relative;
    & i{
      position: absolute;
      right: 40px;
    }
  }

  .center-align-footer {
    text-align: center;
    p {
      padding-bottom: 5px;
      padding-top: 10px;
    }
  }

  .footer-text-box:focus {
    border: 1px solid rgb(208, 51, 31);
  }

  @media only screen and (max-width: 1200px) {
    .footer-sub-text p {
      text-align: center;
      margin: 0px !important;
      height: 27px;
    }

    .footer-sub-text h6 {
      text-align: center;
      font-size: 1rem;
      font-weight: 900;
      padding-top: 15px;
    }
  }

  .privacy-footer {
    padding: 8px 20px !important;
    .display-flex {
      display: flex;
      justify-content: space-between;
    }
    @media only screen and (max-width: 390px) {
      .display-flex {
        flex-direction: column;
        div {
          text-align: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }
  }

  .privacy-footer-span-left {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    a {
      font-size: 15px;
      color: #ffff;
      text-decoration: none;
      // margin-left: 12px;
      white-space: nowrap;
    }
    a:hover {
      color: #606060;
    }
  }

  .privacy-footer-span-right {
    color: #ffff;
    font-size: 15px;
    text-transform: capitalize;
    text-align: center;
  }

  .link-divider{
    margin: 0 5px;
  }
}
