.faq-container-background {
  padding: 0% 0% 0% 0%;
  .faq-title-h1 {
    font-size: 48px !important;
    padding-bottom: 0;
    letter-spacing: 0.05em !important;
    font-weight: 500 !important;
    line-height: 1.2em;
  }
  .according-header {
    background-color: #f7f7f7 !important;
    border: 1px solid #969696 !important;
    padding: 25px 25px 25px 25px;
   
  }

  .according-body {
    background-color: #ffffff !important;
    border-left: 1px solid #969696 !important;
    border-right: 1px solid #969696 !important;
    border-bottom: 1px solid #969696 !important;
    border-top: none !important;
    border-radius: none !important;
    padding: 18px;
  }
  .card {
    border: none;
    border-radius: 0px;
  }
  .accordion {
    border: none;
    overflow-anchor: none;
    border-width: 1px !important;
    border-color: #969696 !important;
  }

  .faq-custome-toggle{
      float: right;
  }
  
}
