.insta-container {
  padding: 0 0% 50px 0%;
  position: relative;
  .react-multi-carousel-list {
    margin: 0px 12px 0px 21px;
    position: unset !important;
  }
  .custom-dot-list-style {
    bottom: 8px !important;
  }

  a.disabled {
    pointer-events: none;
    cursor: default;
  }
}

.insta-h1-title {
  letter-spacing: 0.1em !important;
  font-weight: 300 !important;
  line-height: 1.2em;
  font-family: 'Roboto', sans-serif !important;
  font-size: 38px;
  // margin-bottom: 0;
  padding: 35px 0;
  text-align: center;
}

.insta-post img {
  width: 98%;
  height: auto;
  max-width: 289px;
  background-size: contain;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  margin-right: 5px;
}

.insta-card-view {
  display: flex;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.flex-insta-card {
  width: 100%;
  height: 320px;
  padding: 5px;

  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 992px) {
  .insta-h1-title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 666px) {
  .insta-h1-title {
    font-size: 22px;
  }
}

.react-multi-carousel-dot--active button {
  background-color: red !important;
}

.react-multi-carousel-dot button {
  width: 15px !important;
  height: 15px !important;
  margin-right: 6px;
  border-radius: 100%;
  cursor: pointer;
  background-color: #b1b1b1;
  background-clip: padding-box;
  border: none !important;
  margin-right: 6px !important;
  opacity: 1 !important;
  transition: opacity 0.6s ease;
}

@media only screen and (min-width: 1920px) {
  .insta-post img {
    max-width: unset;
  }
}
