.grouptech-techCard-widgte-background {
  padding: 60px 0px;
  background-color: #f2f2f2;
  .grouptech-img {
    width: 33%;
  }
  @media only screen and (max-width: 990px) {
    .grouptech-img {
      width: 15%;
    }
  }
  @media only screen and (max-width: 600px) {
    .grouptech-img {
      width: 20%;
    }
  }
  @media only screen and (max-width: 480px) {
    .grouptech-img {
      width: 33%;
    }
  }

  @media only screen and (min-width: 410px) and (max-width: 480px) {
    .grouptech-img {
      width: 25%;
    }
  }

  .grouptech-flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .image-container {
      height: 170px;
      width: 100%;
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    @media only screen and (max-width: 990px) {
      .image-container {
        height: 80px;
      }
    }
  }

  .grouptech-flex-container h4 {
    color: black;
    font-weight: normal !important;
    font-size: 20px !important;
  }
  .grouptech-flex-container h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.5;
    color: black;
    font-size: 15px !important;
    font-weight: 400 !important;
  }
  @media only screen and (max-width: 525px) {
    .grouptech-techCard-widgte-background {
      padding: 60px 0px 80px 0px;
    }
  }
}
