.NoSubscriptiondetails-background {
  padding: 60px 0px 0px 0px;
}

.NoSubscriptiondetails-flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.NoSubscriptiondetails-flex-container div {
  margin-bottom: 20px;
}
.NoSubscriptiondetails-h2 {
  letter-spacing: 0.2em;
  text-transform: uppercase;
  padding-bottom: 10px;
  font-family: 'Roboto', Arial, sans-serif !important;
  font-size: 38px;
  font-weight: 300 !important;
}

.NoSubscriptiondetails-h5 {
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-family: 'Roboto', Arial, sans-serif !important;
  font-size: 20px;
  color: #d90e0e;
  font-weight: 500;
}

.NoSubscriptiondetails-package-img {
  max-width: 50%;
}

.NoSubscriptiondetails-free-img {
  max-width: 250px;
  margin-bottom: 10px;
}

.NoSubscriptiondetails-bullentin ul li {
  color: #ec2524;
  padding-bottom: 10px;
  text-align: left;
}

@media only screen and (max-width: 480px) {
  .NoSubscriptiondetails-package-img {
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .NoSubscriptiondetails-h2 {
    font-size: 20px;
    letter-spacing: 2px;
    padding-bottom: 0px;
  }

  .NoSubscriptiondetails-h5 {
    font-size: 16px;
    letter-spacing: 0px;
  }
}
