.product-listing-container {
  padding: 50px 0px;
  .col {
    padding: 5px 5px;
  }
  .product-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
    padding: 10px 0px;
    .product-navigator {
      text-decoration: none;
      width: 100%;
      &:hover {
        .pro-title {
          color: #e0ba67;
        }
      }
    }
    &.card {
      border: none;
      @media only screen and (max-width: 992px) {
        border: 1px solid rgba(0, 0, 0, 0.125);
      }
    }
    span {
      position: absolute;
      right: 15%;
      width: 60px;
      padding: 3px 15px;
      font-size: 11px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      top: 2%;
      background-color: #000;
    }
    .pro-title {
      color: #e09900;
      text-align: center;
      margin: 5px 0px;
      width: 100%;
      margin-top: 21px;
      height: 44px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .pro-price {
      color: #000;
      text-align: center;
      font-weight: 600;
    }
    a.pro-button {
      text-decoration: none;
    }
    .pro-button {
      background-color: #666666;
      color: #fff;
      padding: 8px;
      border-radius: 40px;
      width: 70%;
      justify-content: center;
      cursor: pointer;
      display: flex;
      text-transform: capitalize;
      white-space: nowrap;
      @media only screen and (max-width: 768px) {
        width: 90%;
      }
      i {
        margin-left: 10px;
        &.fas {
          line-height: unset;
        }
      }
    }
    .pro-button:hover {
      background-color: #3f3f3f;
    }
  }
  .product-image {
    height: 150px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    &:hover {
      transform: scale(1.15);
    }
  }
}

@media only screen and (max-width: 612px) {
  .product-listing-container {
    .row {
      margin: 0;
    }
    .col {
      max-width: 48%;
    }
  }
}
