@media only screen and (max-width: 768px) {
  .productListing-container-h1 {
    text-align: center;
    font-size: 20px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: bold;
    letter-spacing: 0.2em;
  }
  .prod-list-desc {
    padding: 0px 10px;
  }
}
