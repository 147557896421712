.return-background {
    padding: 40px 40px 0;
    @media (max-width: 480px){
    padding: 40px 40px 0;
    }
}
.returnContent {
    img {
        width: 100px;
    }
   h2 {
        font-size: 34px!important;
        font-weight: 300!important;
        line-height: 1.25!important;
        }
    @media (max-width: 480px) {
        h2 {
            font-size: 28px!important;
            font-weight: 300!important;
            text-align: center;
        }
    }
    ol {
        margin: 0 0 1.5em 3em;
        padding-inline-start: 0px
        
    }
    li>ol, li>ul {
        margin-bottom: 0;
        margin-left: 1.5em;
    }
    h6 {
        font-size: 16px!important;
        line-height: 1.5em!important;
        color: #666;
        font-weight: 400;
    }
    a {
        color: #de0000;
        text-decoration: none;
    }
    li {
        display: list-item;
        margin-bottom: 1em;
        font-size: 16px;
    }


}

.site-content {
    padding: 0% !important
    ;
}
.elementor-widget:not(:last-child) {
    margin-bottom: 20px;
}



