.update-software-background{
    .updatecontainer-fluid{
        padding: 0px;
    }
    .update-software-img{
        width: 100%;
    }
    .update-software-richtext{
        padding: 5% 0%;
    }
}