.cssFormHeader-container {

    img {
        width: 250px;
        height: 67.08px;
    }
    margin: 0%;
    padding: 1% 0;
}

.elementor-image {
    align-items: center;
    display: flex;
    justify-content: center;
    
}

.successBackground {
    margin-bottom: 12px !important;
    .elementor-image {
        @media (min-width: 767px){
            margin-bottom: 12px !important;;
             }
    }
}

.csform-body {
    padding: 30px;
    background-color: #f2f2f2;
    @media (max-width: 480px){
    padding: 20px 15px;
     }
  }


.successMessage {
    .elementor-widget-container {
        p {
            background: #e0ffc7;
            border: 1px solid #b4d39b;
            padding: 15px;
            }
        @media (max-width: 660px) {
            p {
                font-size: .7em;
            }
        }
        
    }
   
}


.wpforms-confirmation-container-full {
    @media (max-width: 480px)
    {
        padding: 20px 15px;
    }
    padding: 30px;
}

@media (max-width: 660px) {
.elementor-36515 .elementor-element.elementor-element-31dd2e7c {
    font-size: .7em;
}
}

.elementor-widget:not(:last-child) {
    margin-bottom: 0px !important;
}

.elementor-image {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0% !important;
    
}

.css-appbody {

}
