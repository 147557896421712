.container-discountBanner-content {
  text-align: center;
  padding-top: 70px;
  padding-bottom: 70px;
  color: #3f3f3f;

  .h2-heading-discount-mobile{
    box-sizing: border-box;
    font-size: 20px !important;
    font-family: 'Roboto Condensed', sans-serif !important;
    font-weight: bold !important;
    letter-spacing: .2em !important;
  }
}

.h2-heading-discount {
  margin-block-start: 0.83em;
  margin-block-end: 0.5em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 1.2em !important;
  font-size: 38px;
  letter-spacing: 0.1em !important;
  padding-right: 70px;
  padding-left: 70px;
  font-weight: 300 !important;
  font-family: 'Roboto', sans-serif !important;
}

.h6-heading-discount {
  padding-right: 20%;
  padding-left: 20%;
  font-weight: 400;
  color: #666;
}

.img-discount {
  width: 100px;
  height: 100px;
}

.h4-heading-discount {
  font-size: 18px;
  color: #de0000 !important;
  letter-spacing: none;
}

@media only screen and (max-width: 980px) {
  .h2-heading-discount {
    font-size: 24px;
    margin-top: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .h6-heading-discount {
    padding-right: 0%;
    padding-left: 0%;
    font-size: 16px !important;
    letter-spacing: 0em !important;
    margin-bottom: 1em;
    color: #666;
    font-weight: 400;
  }
  .h2-heading-discount {
    padding-left: 0px;
    padding-right: 0px;
    font-size: 16px;
  }
}
