.train-container-wrapper {
  background-color: black;
}

.train-container {
  max-width: 1600px;
  min-height: 45vw;
  height: 45vw;
  margin: auto;
}

.train-fighter-wrapper {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.train-fighter-wrapper a {
  position: relative;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: white;
}

.trainer-product-shop {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 12px 24px;
}

.trainer-product-title {
  position: absolute;
  left: 75%;
  bottom: 50%;
  font-size: 34px;
  font-weight: 500;
  line-height: 1.25;
  text-transform: uppercase;
}

@media (min-width: 1600px) {
  .train-container {
    min-height: 35vw;
    height: 35vw;
  }
}

@media (max-width: 979px) {
  .train-container {
    min-height: 85vw;
    height: 85vw;
  }
  .trainer-product-title {
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }
}

@media (max-width: 480px) {
  .train-container {
    min-height: 110vw;
    height: 110vw;
  }
  .trainer-product-title {
    font-size: 28px;
  }
}
