.confirmcheckout-background {
  padding: 3% 0%;
  .error-message {
    color: #dc3545;
    display: inline-block;
    font-size: 13px;
  }
  .error-field {
    border: 1px solid#dc3545;
  }

  .confirmcheckout-checkout-h1 {
    font-size: 48px;
    padding-bottom: 0;
    letter-spacing: 0.05em !important;
    font-weight: 500 !important;
    line-height: 1.2em;
    margin-bottom: 0px;
  }
  .app-checkout-guildelines {
    font-size: 13px;
  }
  .confirmcheckout-checkout-h5 {
    font-style: italic;
    font-size: 20px;
    padding-bottom: 10px;
    font-weight: 400;
    line-height: 1.5em !important;
    color: #666;
  }
  .confirmcheckout-ahref {
    color: #e09900;
  }
  .error-message-astrid-show {
    color: red;
  }
  .confirmcheckout-ahref:hover {
    color: black;
    text-decoration: none;
  }
  .app-note {
    font-size: 13px;
    line-height: 1.3;
    margin: 8px 0 0 0;
    color: #666;
  }
  .confirmcheckout-status {
    padding: 0;
    li {
      margin-right: 2em;
      text-transform: uppercase;
      font-size: 0.715em;
      line-height: 1;
      border-right: 1px dashed #d3ced2;
      padding-right: 2em;
      margin-left: 0;
      padding-left: 0;
      list-style-type: none;
      color: #666;
      strong {
        display: block;
        font-size: 1.4em;
        text-transform: none;
        line-height: 1.5;
      }
    }
    li:last-of-type {
      border: none;
    }
  }
  .confirmcheckout-title {
    margin-top: 2%;
    font-size: 38px !important;
    padding-bottom: 10px;
    font-weight: 500 !important;
    line-height: 1.2em;
    color: #666;
  }
  .confirmcheckout-table {
    padding-top: 10px;

    .confirmcheckouttable-product-header {
      padding: 20px !important;
      border: 0;
      background-color: rgba(0, 0, 0, 0.03);
      font-weight: 700;
      line-height: 1.5em;
      border: none !important;
      color: #666;
    }

    .confirmcheckout-totale-amount {
      padding: 20px !important;
      vertical-align: middle;
      line-height: 1.5em;
      border: none !important;
      font-weight: 700;
      color: #666;
    }

    .confirmcheckout-body-total {
      background-color: rgba(0, 0, 0, 0.005);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
      padding: 20px !important;
      border: 0;
      vertical-align: middle !important;
      line-height: 1.5em;
      font-weight: 400;
      color: #666;
    }

    .confirmcheckout-body-bundle-total {
      background-color: rgba(0, 0, 0, 0.005);
      padding: 20px !important;
      border: none !important;
      vertical-align: middle !important;
      line-height: 1.5em;
      font-weight: 400;
      color: #666;
    }
    .confirmcheckout-image {
      max-width: 200px;
      max-height: 200px;
    }
    .confirmcheckout-page-item {
      padding: 20px;
      color: #666;
    }
    .confirmcheckout-tr-noborder {
      text-align: center;
      color: #666;
      border: none !important;
    }
  }
  .border-top-none {
    border-top: none;
  }
  .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .no-bold {
    font-weight: normal !important;
  }
  @media only screen and (max-width: 980px) {
    h3,
    h4,
    h5,
    h6 {
      font-size: 16px !important;
    }
    h3 {
      letter-spacing: 0.2em !important;
      font-weight: bold;
    }
  }
  .was-validated .form-control:valid,
  .form-control.is-valid {
    background-image: none;
  }
}
