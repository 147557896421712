.app-article {
  .app-article-header {
    h1 {
      font-size: 48px;
      line-height: 1.2em;
      @media only screen and (max-width: 992px) {
        font-size: 28px;
      }
    }
    p {
      font-size: 85%;
      color: #595959;
      a {
        text-decoration: none;
        color: #595959;
        &:hover {
          color: #007bff;
        }
      }
    }
  }
  .app-article-body {
    margin-top: 15px;
  }
  .app-article-footer {
    margin-top: 2em;
    .app-paginate-btn {
      margin-top: 5px;
      font-size: 85%;
      .prev {
        margin-right: 5px;
        transform: scale(0.7);
      }
      .next {
        margin-right: 5px;
        transform: scale(0.7) rotate(180deg);
      }
      a {
        text-decoration: none;
        color: #595959;
        &:hover {
          color: #007bff;
        }
      }
    }
  }
}
