.product-key-feature-container {
  margin-top: 30px;
  h1.title {
    text-transform: uppercase;
    text-align: center;
    font-weight: 300;
    letter-spacing: 5px;
    text-shadow: none;
  }
  @media only screen and (max-width: 992px) {
    h1.title {
      text-transform: capitalize;
      font-size: 28px;
    }
    .content-align { 
      margin: 20px 0px 50px !important;
    }
    .productfeature-p{
      margin: 0;
      color: #E00303;
      padding-bottom: 30px;
    }
  }
  .prod-img {
    background-size: contain;
    background-repeat: no-repeat;
    height: 500px;
    width: 500px;
    position: relative;
    background-position: center;
    .pointer-container {
      display: flex;
      position: absolute;
      align-items: center;
      &.right {
        flex-direction: row-reverse;
      }
    }

    .prod-feature-par {
      background-color: #fce9ea;
      height: 45px;
      width: 45px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      .prod-feature-sub-par {
        background-color: #ef8b8e;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        .prod-feature-keys {
          background-color: #e10302;
          height: 25px;
          width: 25px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 15px;
        }
      }
    }

    .prod-description {
      display: flex;
      flex-direction: column;
      width: 260px;
      margin-left: 10px;
      margin-right: 10px;
      &.right {
        text-align: end;
      }
      .title {
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 500;
      }
      .description {
        font-size: 0.9rem;
      }
    }
  }

  .content-align {
    display: flex;
    justify-content: center;
    margin: 90px 0px 50px;
  }

  .prod-img-mobile {
    background-size: contain;
    background-repeat: no-repeat;
    height: 300px;
    width: 300px;
    position: relative;
    background-position: center;
    .prod-feature-par {
      background-color: #ebebeb;
      height: 40px;
      width: 40px;
      position: absolute;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      cursor: pointer;
      .prod-feature-sub-par {
        background-color: #959595;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        .prod-feature-keys {
          background-color: #242424;
          cursor: pointer;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 12px;
        }
      }
      &.active {
        .prod-feature-sub-par {
          background-color: #ef8b8e;
        }
        .prod-feature-keys {
          background-color: #e10302;
        }
        background-color: #fceaea;
        animation: pulse 1s 3 ease-out;
      }
      @keyframes pulse {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(0.85);
        }
        100% {
          transform: scale(1);
        }
      }
    }
  }

  .mobile-prod-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    .prod-feature-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 0px;
      .title {
        font-size: 24px;
        text-align: center;
      }
      .des {
        text-align: center;
      }
    }
  }
}
