.app-article-type-card {
  margin-bottom: 2rem;
  a {
    color: #e09900;
    text-decoration: none;
  }
  a:hover {
    color: #e0ba67;
  }
  .app-article-header {
    h2 {
      padding-bottom: 10px;
      a {
        color: #e09900;
        text-decoration: none;
        font-size: 38px;
      }
      a:hover {
        color: #e0ba67;
      }
      @media only screen and (max-width: 992px) {
        a {
          font-size: 20px !important;
          letter-spacing: 0.2em;
          font-weight: 400;
        }
      }
    }
    p {
      font-weight: 400;
      line-height: 1.5em;
      font-size: 85%;
      color: #595959;
      @media only screen and (max-width: 992px) {
        font-size: 16px;
      }
      a {
        color: #595959;
        text-decoration: none;
      }
      a:hover {
        color: #007bff;
      }
    }
  }
  .app-article-summary {
    p {
      line-height: 1.5em;
      color: #666;
    }
  }
  .app-article-footer {
    margin-top: 20px;
    .app-comment-article-type-card {
      font-weight: 400;
      line-height: 1.5em;
      margin-bottom: 5px;
      font-size: 85%;
      color: #595959;
      a {
        color: #595959;
        text-decoration: none;
      }
      a:hover {
        color: #007bff;
      }
      i {
        margin-right: 5px;
      }
    }
  }
}
