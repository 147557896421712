.ColorBar-background{
    padding: 5% 0% 5% 0%;
    background-color: #f9f9f9;

  .ColorBar-img {
    border-radius: 15px;
    max-width: 200px;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .ColorBar-flex-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .ColorBar-h2{
    letter-spacing: 0em ;
    font-size: 38px ;
    padding-bottom: 10px;
    font-weight: 500 !important;
    line-height: 1.2em;
    text-align: center;
  }
  .ColorBar-h5{
    font-size: 20px !important;
    padding-bottom: 10px;
    font-weight: 400;
    text-align: center;
  }
  
  }
  
  
  @media only screen and (max-width: 525px) {
    .ColorBar-background{
      padding: 60px 0px 80px 0px;
    }
    .ColorBar-h2{
     
      font-size: 20px !important ;
    }

    .ColorBar-background .ColorBar-h5 {
      font-size: 16px !important;
      
  }
  }
  
