.app-review-stars-container {
    margin: 10px 0px;
  .app-stars {
    margin-right: 5px;
    cursor: pointer;
    &.active {
      color: #de0000;
    }
    &.inactive {
      color: white;
      stroke: #de0000;
    }
  }
}
