.app-comment-section {
  margin-top: 30px;
  .app-comment-title {
    .app-cancel-comment {
      font-size: 85%;
      color: #e09900;
      margin-left: 10px;
      cursor: pointer;
      &:hover {
        color: #e0ba67;
      }
    }
  }
  @media only screen and(max-width: 992px) {
    .app-comment-title {
      font-size: 16px;
      letter-spacing: 0.2em;
      line-height: 1.2em;
      font-weight: bold;
    }
  }
  input[type='text'] {
    width: 50%;
    @media only screen and(max-width: 992px) {
      width: 100%;
    }
  }
  input[type='email'] {
    width: 50%;
    @media only screen and(max-width: 992px) {
      width: 100%;
    }
  }
  .form-control-con {
    margin-bottom: 15px;
  }
  .app-form-submit {
    margin-top: 15px;
    width: 150px;
    padding: 10px 0px 10px;
    background-color: #666666;
    color: #fff;
  }
  .error-message {
    color: #dc3545;
    display: inline-block;
    font-size: 13px;
  }
  .error-field {
    border: 1px solid#dc3545;
  }
}
