.trainerImageCard-background {
  padding: 60px 0px 0px 0px;
}

.trainerImageCard-flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.trainerImageCard-title-h1 {
  font-size: 38px;
  padding-bottom: 10px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: 300;
  line-height: 1.2em;
}

.trainerImageCard-subtitle-h5 {
  color: #d90e0e;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 20px;
  font-weight: 300;
}

@media only screen and (max-width: 768px) {
  .trainerImageCard-flip-center {
    position: relative;
    top: 0px !important;
    display: flex;
    width: 100%;
    height: auto !important;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }
  .trainerImageCard-title-h1 {
    padding-top: 20px;

    text-transform: none;
    color: white;
    font-size: 28px !important;
    font-family: 'Roboto', Arial, sans-serif !important;
    letter-spacing: 0 !important;
    font-weight: 300 !important;
  }

  .trainerImageCard-flip-center {
    background-color: #231e1f;
  }

  .trainerImageCard-subtitle-h5 {
    letter-spacing: 1px;
    text-transform: none;
    font-size: 20px;
    font-size: 16px !important;
    padding-bottom: 10px;
  }

  .trainerImageCard-background {
    padding: 0px 0px 0px 0px;
  }

  .trainerImageCard-containerfluid {
    padding-right: 0px;
    padding-left: 0px;
  }

  .trainerImageCard-flex-container {
    background-color: #231e1f;
  }

  .trainerImageCard-flip-box {
    height: 525px;
    margin-bottom: 0px !important;
  }
}

.trainerImageCard-flip-box {
  height: 525px;
  margin-bottom: 20px;
}

.trainerImageCard-img {
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
}

.trainerImageCard-flip-Box-Footer {
  text-align: center;
  justify-content: flex-end;
  height: 101.5%;
  display: flex;
  width: 100%;
  flex-direction: column;
  transition: all ease-in-out 0.5s;
}

.trainerImageCard-flipBox-h3 {
  font-size: 18px !important;
  letter-spacing: 0.2em !important;
  line-height: 1.3em !important;
  background-color: rgba(0, 0, 0, 0.4);
  padding-bottom: 10px;

  font-family: 'Roboto', Arial, sans-serif !important;
  padding-top: 10px;
  font-weight: 300 !important;
  color: white;
}

.trainerImageCard-flip-center-h3 {
  font-size: 18px !important;
  letter-spacing: 0.2em !important;
  line-height: 1.3em !important;
  color: #fff;
  font-family: 'Roboto', Arial, sans-serif !important;
  font-weight: 300 !important;
  transition: all ease-in-out 0.5s;
  opacity: 0;
}
.trainerImageCard-flip-center {
  position: relative;
  top: -532px;
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  flex-direction: column;

  justify-content: center;
}

.trainerImageCard-flip-center-text {
  color: #fff;
  font-size: 14px;
  transition: all ease-in-out 0.5s;
  opacity: 0;
}
.trainerImageCard-flip-box :hover .trainerImageCard-flip-Box-Footer {
  opacity: 0;
}

.trainerImageCard-flip-box :hover .trainerImageCard-flip-center-text {
  opacity: 1;
}

.trainerImageCard-flip-box :hover .trainerImageCard-flip-center-h3 {
  opacity: 1;
}

.trainerImageCard-flip-box :hover .trainerImageCard-flip-center {
  background-color: rgba(0, 0, 0, 0.4);
}

@media only screen and (max-width: 768px) {
  .trainerImageCard-flip-center-text {
    opacity: 1;
  }

  .trainerImageCard-flip-center-h3 {
    opacity: 1;
  }

  .trainerImageCard-flip-Box-Footer {
    opacity: 0;
  }
}

@media only screen and (max-width: 768px) {
  .trainerImageCard-flip-box {
    display: flex;
    justify-content: center;
  }
}
