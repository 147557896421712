.testimonial-review-slider-container {
  position: relative;
  padding: 50px 0;
}

.testimonial-review-slider-spacing {
  padding-top: 0;
}

.testimonial-review-slider-container-landing {
  height: 375px;
  background-color: black;
  .testiminial-wrapper {
    background-color: #2f3438;
    max-width: 1600px;
    margin: 0 auto;
    border-radius: 10px;
  }
}

.review-star-container {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  height: 325px;
  margin: auto;
  padding: 50px;
}

.review-star-container-landing{
  padding: 50px 92px;
  height: 375px;
  position: relative;
  // max-width: 1200px;
}

.review-testimonial-title {
  position: relative;
  font-size: 32px;
  line-height: 1.25;
  margin-top: 15px;
  white-space: break-spaces;
  max-width: 790px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.review-testimonial-landing-title {
  position: relative;
  font-size: 32px;
  line-height: 1.25;
  margin-top: 45px;
  white-space: break-spaces;
  // overflow: auto;
  //   &::-webkit-scrollbar {
  //       width: 5px;
  //   }
  //   &::-webkit-scrollbar-track {
  //       background: #f1f1f1;
  //   }
  //   &::-webkit-scrollbar-thumb {
  //   background: #888;
  // }
  // &::-webkit-scrollbar-thumb:hover {
  //   background: #555;
  // }
}

.add-max-width {
  max-width: 790px;
  height: 325px;
}

.reviewer-details {
  margin-top: 30px;
  display: block;
  font-size: 20px;
  line-height: 1.5;
}

.reviewer-details span:first-child {
  font-weight: 500;
  color: #d3222a;
}

.hex-left-top {
  position: absolute;
  left: 10%;
  top: 15%;
  transform: scale(1.5);
}

.hex-right-bottom {
  right: 10%;
  position: absolute;
  bottom: 15%;
  transform: scale(-1.5);
}

.slick-dots {
  bottom: 0;
}

.testimonial-review-slider-container-landing .slick-dots {
  bottom: 35px;
}

.slick-dots li {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  margin: 0 3px;
}

.slick-dots.slick-thumb li.slick-active {
  .slider-circle {
    background: red;
    opacity: 1;
  }
  .slider-circle-landing {
    background: #fc5310;
    opacity: 1;
  }
}

.slider-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: grey;
  opacity: 0.6;
  position: relative;
}

.slider-circle-landing {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: grey;
  opacity: 0.6;
  position: relative;
}

.landing-testimonial2 {
  font-size: 28px;
  line-height: 1.25;
  position: relative;
}

.landing-testimonial-quote {
  width: 100%;
  position: absolute;
  top: -65px;
  font-size: 100px;
  color: rgb(252, 83, 16);
}

.landing-color {
  color: white;
}

.landing-accent{
  color: #fc5310;
}

.loves-nexersys {
  position: absolute;
  top: 0;
  color: white;
  font-size: 35px;
  font-weight: 500;
  margin-top: 10px;
  line-height: 1.3;
}

.testimonial-info-container{
  max-width: 1200px;
}

@media (max-width: 735px) {
  .review-testimonial-title {
    font-size: 24px;
  }
  .reviewer-details {
    font-size: 15px;
  }
  .testimonial-review-slider-container-landing .reviewer-details {
    font-size: 20px;
  }
  .hex-left-top {
    left: 5%;
    transform: scale(1);
  }

  .hex-right-bottom {
    right: 5%;
    bottom: 10%;
    transform: scale(-1);
  }
  .review-testimonial-landing-title{
    margin-top: 10px;
  }
}

@media (max-width: 979px){
  .testimonial-review-slider-container-landing {
    height: 550px;
  }
  .review-star-container-landing{
    height: 550px;
  }
}

@media (max-width: 735px){
  .landing-testimonial2 {
    font-size: 20px;
  }
  .review-star-container-landing {
    padding: 15px 20px;
  }
  .landing-testimonial-quote {
    display: none;
  }
  .testimonial-info-container{
    max-width: 290px;
    margin-top: 0;
  }
}

  @media (max-width: 480px){
    .loves-nexersys {
      font-size: 28px;
    }
  }
