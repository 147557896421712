.ContactFormAccordion-container {
  .ContactFormAccordion-header {
    font-family: 'Franklin Gothic Book', Roboto, Franklin Gothic Book sans-serif;
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    color: #ffffff;
  }
  .ContactFormAccordion-single-bg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .ContactFormAccordion-collape-background {
    background-color: #f7f7f7;
    border-bottom: none !important;
  }
  .ContactFormAccordion-body {
    padding: 2% 2%;
    background-color: #f7f7f7;
  }

  .error-message-astrid-show {
    color: red;
    margin-left: 5px;
  }

  .Contact-form-errorMessage{
    color: #dc3545;
  }
  .error-message-astrid-noshow {
    color: red;
    display: none;
  }

  .app-info-popup {
    margin-left: 6px;
    cursor: pointer;
    height: 22px;
    width: 14px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .was-validated .form-check-input:valid ~ .form-check-label,
  .form-check-input.is-valid ~ .form-check-label {
    color: black;
  }

  .was-validated .form-control:valid,
  .form-control.is-valid {
    border-color: #ced4da;
    padding-right: calc(1.5em + 0.75rem);
    background-image: none;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .files input {
    outline: none;
    outline-offset: -10px;
    -webkit-transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    height: 150px;
    z-index: 7;
    position: relative;
    opacity: 0;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
  }
  .files input:focus {
    outline: none;
    outline-offset: -10px;
    -webkit-transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    border: 1px solid #b39292;
  }

  .files {
    position: relative;
    input {
      cursor: pointer;
    }
    .app-clear {
      position: absolute;
      top: 0;
      right: 5px;
      z-index: 10;
      cursor: pointer;
    }
    .app-file-upload-container {
      border: 1px dashed #ccc;
      border-radius: 2px;
      background: #fcfcfc;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      position: absolute;
      top: 0;
      width: 100%;
      height: 150px;
      z-index: 4;
      .app-selcted-image {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        background: white;
        position: relative;
        .app-image-preview {
          height: 50px;
          width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .app-details {
          margin-left: 15px;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
  .files:after {
    pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 50px;
    right: 0;
    height: 56px;
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .color input {
    background-color: #f1f1f1;
  }
  .files:before {
    position: absolute;
    bottom: 10px;
    left: 0;
    pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    display: block;
    margin: 0 auto;
    color: #a52e2e;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
  }

  .btn-contactbutton {
    background-color: #eee;
    border: 1px solid #ddd;
    color: #333;
    font-size: 1em;
    padding: 10px 15px;
  }

  .form-label-bold {
    font-weight: bold;
    display: flex;
  }
}

.generalHeaderInfo {
  font-weight: bold;
  color: red;
}