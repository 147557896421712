.affirm-as-low-as {
  margin-bottom: 0;
  font-size: 0;
}
.affirm-as-low-as a::after {
  content: "Financing Options Available";
  display: block;
  font-size: 16px;
  line-height: 2em;
  text-decoration: underline;
  color: #8b8b8b;
}
