.socialMediaBanner-background{
    background-position: center center;
    background-size: cover;
    background-color: #D4DCDF;
    padding: 4% 0% 5% 0%;

}

.socialMediaBanner-flex-container{
      
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.socialMediaBanner-h1{
    font-size: 38px !important;
    padding: 40px 0 20px;
    font-family: 'Roboto', Arial, sans-serif !important;
    letter-spacing: 0.2em !important;
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: 300 !important;
}

.socialMediaBanner-h6{
    color: #fff;
    padding-bottom: 20px !important;
    font-family: 'Roboto', Arial, sans-serif !important;
    letter-spacing: 0 !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 1.5em !important;
}

.socialMediaBanner-socialicon-flex{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.socialMediaBanner-socialicon-flex a img{
    max-width: 50px;
    margin: 10px 10px 20px;
}


 
@media only screen and (max-width: 480px) {

   

    .socialMediaBanner-h1{
        font-size: 28px !important;
    }
    
  
    .socialMediaBanner-socialicon-flex a img{
        width: 30px;
        margin: 10px 10px 20px;
    }
    
    .socialMediaBanner-background{
       
        padding: 0%
    
    }
    
  }
  
  
  
