.offer-banner-widget {
  text-align: center;
  letter-spacing: 0.1em;
  color: #333;
  font-size: 15px;
  font-weight: 500;
  padding: 10px;
  position: absolute;
  width: 100%;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.5);
  p {
    margin: 0 !important;
  }
}

@media only screen and (max-width: 990px) {
  .offer-banner-widget {
    display: none;
  }
}
