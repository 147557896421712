.ImageTableCausel-background {
  padding: 5% 0% 5% 0%;
  background-color: white;

  .btncarousel-widget-arrow-right {
    right: calc(3% + 1px);
  }

  .btncarousel-widget-arrow-left {
    left: calc(3% + 1px);
  }
  .btncarousel-widget {
    position: absolute;
    outline: 0;
    transition: all 0.5s;
    border-radius: 35px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
  }

  .ImageTableCausel-arrow {
    color: #fcfcfc;
    font-size: 58px;
  }

  .Image-table-button-buynow {
    background: #de0000;
    border-radius: 40px;
    color: #fff;
    padding: 13px 30px;
    font-size: 18px;
    border: 1px solid #de0000;
  }

  .Image-table-button-buynow:hover {
    text-decoration: none;
  }

  .Image-table-button-h6 {
    margin-top: 30px;
  }
}

.ImageTableCausel-img {
  border-radius: 15px;
  max-width: 200px;
  width: 100%;
  margin-bottom: 20px;
}

.ImageTableCaousel-flex-image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0% 14% 0% 14%;
  width: 100%;
}

.ImageTableCaousel-flex-image col {
  padding: 10px;
  width: 14%;
}

.ImageTableContainer-img {
  padding: 0 2%;
  border-radius: 40px;
  width: 100%;
  max-width: 372px;
}

.ImageTableCarousel-excercise-img {
  max-width: 45px;
  margin-bottom: 10px;
}

.ImageTableCarousel-excercise-img-h6 {
  font-size: 16px !important;
  line-height: 1.5em !important;
  color: #666;
}

.ImageTableCausel-flex-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.ImageTableCausel-h2 {
  letter-spacing: 0em;
  font-size: 38px;
  padding-bottom: 10px;
  font-weight: 500 !important;
  line-height: 1.2em;
  text-align: center;
}
.ImageTableCausel-background .react-multi-carousel-list {
  height: 559px;
}

@media only screen and (max-width: 525px) {
 

  .ImageTableCaousel-flex-image {
    padding: none;
  }
}

@media only screen and (max-width: 725px) {
  .ImageTableCaousel-flex-image {
    padding: 0px;
  }

  .ImageTableCausel-h2 {
    font-family: 'Roboto Condensed', sans-serif !important;
    font-weight: bold !important;
    font-size: 20px !important;
    
}
}
