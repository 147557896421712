.product-details-view-container {
  margin: 50px 0px;
  .variant-desc {
    margin-top: 10px;
  }
  .label-price {
    font-size: 20px;
  }
  .display-flex {
    display: flex;
    align-items: center;
  }
  .form-label {
    margin: 0px 15px 0px 0px;
  }
  .pos-rel {
    position: relative;
  }
  .prod-search-icon {
    position: absolute;
    top: 10px;
    right: 30px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 18px;
  }
  .prod-image {
    height: 340px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .prod-image-single {
    height: 340px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .prod-dots {
    background-position: center;
    background-size: contain;
    height: 50px;
    background-repeat: no-repeat;
    width: 70px;
  }
  .react-multi-carousel-dot-list {
    position: unset;
    margin-top: 10px;
  }
  .active {
    opacity: 1;
  }
  .inactive {
    opacity: 0.5;
  }
  .add-to-cart-button {
    outline: none;
    padding: 10px 20px;
    color: #fff;
    font-weight: bold;
    background-color: #de0000;
    background-image: linear-gradient(180deg, #de0000 0%, #b2020c 100%);
    border: none;
    margin-top: 1px;
    border-radius: 40px;
    cursor: pointer;
    text-align: center;
    &:focus {
      color: #000;
      background-color: #f9fafb;
      border-color: #f9fafb;
      -webkit-box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 50%);
      box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 50%);
    }
  }

  .input-add-to-button {
    display: flex;
    margin-top: 10px;
  }
  @media only screen and (max-width: 410px) {
    .input-add-to-button {
      flex-direction: column;
      & div:last-child {
        margin-top: 20px;
      }
    }
    .add-to-cart-button {
      margin-top: 15px;
    }
  }

  .productdetail-affirm {
    padding-top: 20px;
    padding-bottom: 5px;
    color: #666;
    a {
      color: #e09900;
    }
    a:hover {
      text-decoration: none;
      color: #e0ba67;
    }
  }

  .features-adjust {
    h3 {
      font-size: 28px;
      font-weight: 500;
      line-height: 1.2em;
      padding-top: 10px;
    }
    p {
      line-height: 1.5em;
      color: #666;
    }
    // .features-pre p{
    //   white-space: pre;
    // }
    ul {
      margin: 0 0 1.5em 3em;
      padding: 0;
    }
    .additional-info-descp {
      p {
        margin-bottom: 0;
      }
    }
    @media only screen and (max-width: 980px){
      h3 {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: .2em;
      }
    }
  }
}

.single-product-pre {
  .affirm-as-low-as {
    font-size: 16px;
  }
  p {
    // line-height: 1.5em !important;
    // color: #666;
    margin: 1.5em 0;
  }
  a {
    color: #e0ba67;
    text-decoration: none;
  }
}
