.productbadge-badge-widgte-background {
  padding: 2% 0%;
  background-color: #f2f2f2;
  & .learn-more {
    text-decoration: underline;
    cursor: pointer;
    color: #666666;
  }
}

.widget-black-bg{
  background-color: black;
  padding: 30px 0;
}

.widget-black-bg h5{
  color: white;
}

.widget-black-bg .learn-more{
  color: white;
}

.productbadge-badge-widgte-background {
  .row {
    justify-content: center;
  }
}
.productbadge-img {
  width: 110px;
  height: 75px;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.productbadgeflex-container {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.productbadgeflex-container h5 {
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
  margin-bottom: 20px;
  text-transform: uppercase;
  white-space: pre;
}

.productbadgeflex-container h6 {
  font-size: 14px !important;
  box-sizing: border-box;
  line-height: 1.5em !important;
  color: #666;
  font-weight: 400;
}
.productbadgeflex-container a {
  color: #666;
  text-decoration: underline;
}
@media only screen and (max-width: 525px) {
  .grouptech-techCard-widgte-background {
    padding: 60px 0px 80px 0px;
  }

  .productbadgeflex-container h5 {
    font-size: 13px !important;
  }
}
