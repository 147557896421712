.startBoxing-background{
    background-color: #222222;
    padding: 04% 0% 1% 0%;
}


.startBoxing-flex-container{
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    
  }
  .startBoxing-flex-container div{
  
    margin-bottom: 35px;
    
  }

  .startBoxing-img{
    max-width: 75%
    
  
  }
  
  
  .startBoxing-shopnow-btn a{
    background-color: #E10203;
    text-align: right;
    margin-right: 0;
    border-radius: 40px;
    color: #fff;
    padding: 12px 40px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    
  }

  


  .startBoxing-shopnow-btn a:hover{
   text-decoration: none;
   color: #fff;

  }

  .startBoxing-LearnMoreLink{
    letter-spacing: 0.2em;
    text-transform: uppercase;
    background-color: transparent;
    text-align: right;
    margin-right: 0;
    border-radius: 40px;
    color: #fff;
    padding: 12px 40px;
    font-size: 20px;
    font-weight: 500;
    text-decoration: underline;
  }

  .startBoxing-LearnMoreLink:hover{
    text-decoration: none;
    color: #fff;
    text-decoration: underline;
  }

  .startBoxing-affrim img{
    max-width: 60px;
    padding-left: 5px;
    position: relative;
    top:-6px

  }
  .startBoxing-affrim a{
    color: #fff;

  }

  .startBoxing-affrim a:hover{
    text-decoration: none;

  }

  .startBoxing-affrim h6{
   
    font-weight: 400;
  }

 
@media only screen and (max-width: 480px) {

  .startBoxing-img{
    max-width: 100%;
  }
   
  .startBoxing-shopnow-btn a{
    font-size: 16px;
    
  }

  .startBoxing-LearnMoreLink{
    font-size: 16px;
    letter-spacing: 0em ;
  }

  .startBoxing-background{

    padding: 12% 0% 0% 0%;
}

  
}



