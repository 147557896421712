.app-article-card.col {
  padding-bottom: 15px;
  .card {
    transition: all 0.25s;
    border: 0 solid #818a91;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    .card-img-top {
      margin-bottom: 20px;
      height: 150px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      @media only screen and (min-width: 768px) {
        height: 250px;
      }
      .overlay {
        height: 100%;
        width: 100%;
        background-color: black;
        z-index: 1;
        opacity: 0.12;
      }
    }
    .card-body {
      .read-more {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #e09900;
        text-decoration: none;
        &:hover {
          color: #e0ba67;
        }
      }
      .article-title {
        font-size: 12px;
        font-weight: 700;
        color: #e09900;
        text-decoration: none;
        &:hover {
          color: #e0ba67;
        }
      }
      .card-title {
        margin-bottom: 25px;
        font-size: 28px;
        font-weight: normal;
      }
      @media only screen and (max-width: 992px) {
        .card-title {
          margin-bottom: 25px;
          font-size: 16px;
          line-height: 1.2em;
          letter-spacing: 0.2em;
          font-weight: 400;
        }
      }
      .card-text {
        font-size: 14px;
        color: #777;
        line-height: 1.5em;
        margin-bottom: 25px;
      }
    }
    .card-footer {
      margin-top: 20px;
      color: #adadad;
      line-height: 1.3em;
      font-size: 12px;
      padding: 15px 30px;
      background-color: white;
      span:first-child::after {
        margin: 0 7px;
        content: '•';
      }
    }
    &:hover {
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
      .card-img-top {
        .overlay {
          opacity: 0.1;
        }
      }
    }
  }
}
