.img-staring {
  width: 200px;
  // height: 40px;
}

.opacity-overlay{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
}

.container-background-content {
  text-align: center;
  // padding-top: 30px;
  // padding-bottom: 70px;
  position: relative;
}
.transpertant-rating {
  color: #ef3e4a;
  transform: scale(2);
  margin-right: 20px;
}

.h2-heading {
  color: #ffffff;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 1.2em !important;
  font-size: 32px !important;
  font-weight: 300;
  letter-spacing: 0.1em !important;

  margin-bottom: 1em;
  font-family: 'Roboto', sans-serif !important;
}

.container-fluid-sizing {
  padding: 0px !important;
}

.h5-heading {
  color: #ffffff;
  margin-bottom: 1em;
  font-size: 28px;
  // font-style: italic;
  letter-spacing: 0.01em !important;
  line-height: 1.5em;
  padding-bottom: 10px;
  font-weight: 300 !important;
  padding-top: 5px;
}

.h6-heading {
  color: #ffffff;
  font-size: 20px;
  // font-style: italic;
}

.h6-heading-bold {
 font-weight: 500;
}

.Scroller-Effect {
  /* The image used */

  /* Set a specific height */
  min-height: 300px;
  width: 100%;
  padding: 5% 0%;
  position: relative;

  /* Create the parallax scrolling effect */
  // background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (max-width: 980px) {
  .h5-heading {
    font-size: 16px !important;
  }

  .h2-heading {
    font-size: 24px !important;
    margin-top: 25px;
  }

  .Scroller-Effect {
    /* Create the parallax scrolling effect */
    background-attachment: inherit;
  }
}

@media (max-width: 480px){
  .container-fluid-sizing{
    display: none !important;
  }
}