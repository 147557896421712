.VideoWidgetItemImg {
  display: block;
  transition: transform 0.4s;
  width: 12rem;
  cursor: pointer;
}

.VideoWidgetItem {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 981px) {
  .VideoWidgetItemImg {
    filter: grayscale(80%);
  }
  
  .VideoWidgetItemImg:hover {
    transform: scale(1.1);
    transform-origin: 50% 50%;
    filter: none;
  }
}
