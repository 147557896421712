.productFeatureContent-background {
  background-color: #fafafa;

  background-size: cover;
  background-position: center top;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  min-height: 600px;
}

.productFeatureContent-flex-container {
  padding-top: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.productFeatureContent-h2 {
  letter-spacing: 0em;
  font-size: 38px;
  padding-bottom: 10px;
  font-weight: 500 !important;
  line-height: 1.2em;
  text-align: center;
}
.productFeatureContent-h6 {
  font-size: 16px !important;
  line-height: 1.5em !important;
  color: #666;
}

.productFeatureContent-h6-hyperlink span {
  text-decoration: underline !important;
  color: #1ba4c7 !important;
  cursor: pointer;
}

.productFeatureContent-h6-hyperlink {
  margin-top: 10px;
  margin-bottom: 10px;
}
.productFeatureContent-button a {
  background-color: #de0000;
  text-align: right;
  margin-right: 0;
  border-radius: 40px;
  color: #fff;
  padding: 13px 30px;
  font-size: 18px;
  margin-top: 100px;
  text-decoration: none;
}

.productFeatureContent-button {
  font-size: 18px;
  margin-top: 20px;
}

.productFeatureContent-button:hover {
  text-decoration: none;
  color: white;
}

@media only screen and (max-width: 525px) {
  .productFeatureContent-background {
    padding: 20px 0px 80px 0px;
  }
}

@media only screen and (max-width: 779px) {
  .productFeatureContent-background {
    background-color: #fafafa;

    background-image: none;
  }

  .productFeatureContent-h2 {
    font-size: 20px !important;
  }

  .productFeatureContent-img {
    width: 100%;
  }

  .productFeatureContent-flex-container {
    padding-top: 2%;
  }
}
