.According-classBody {
  padding: 2% 8% !important;
  background-color: #f2f2f2;
}

.faq-container-landing{
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: black;
}

.video-animation-bg {
  background-color: #23272d;
  padding: 0;
}

.faq-wrapper{
  max-width: 1200px;
  padding: 10px;
  margin: 0 auto;
}

.faq-wrapper .card {
  background-color: #231f20;
  margin: 10px;
  border: 0;
}

.faq-wrapper .according-header{
  font-size: 1.1em;
  font-weight: 500;
  border: 0;
  text-align: center;
  // padding-bottom: 0;
  padding: 15px;
  background-color: #231f20 !important;
  color: white;
  line-height: 1.25;
}

.faq-animation-wrapper {
  .according-header{
  background-color: #23272d !important;
  padding: 10px;
  }
  .card {
    background-color: #23272d;
  }

}
.faq-wrapper .according-header i{
  color: #fc5310 !important;
}

.faq-animation-wrapper .according-header i{
  color: white !important;
}

.faq-wrapper .according-body{
  padding: 0.5em 1.5em;
  padding-top: 0;
  border: 0;
  background-color: #231f20;
  color: white;
  text-align: center;
}

.faq-animation-wrapper .according-body{
  background-color: #23272d;
}

.faq-landing-title-container{
  margin-bottom: 20px;
}

.faq-landing-title-container p{
  margin-bottom: 0;
  font-size: 34px;
  color: white;
  text-align: center;
  font-weight: 500;
  line-height: 1.25;
}

.accordion-single-bg {
  background-color: black;
  color: white;
  text-align: center;
}

@media (max-width: 979px){
  .faq-wrapper{
    padding: 0 30px;
    max-width: 1024px;
  }
}

@media (max-width: 480px){
  .faq-wrapper{
    padding: 15px 30px;
    max-width: 767px;
  }
  .faq-animation-wrapper {
    padding: 0 15px 30px;
    padding-bottom: 0;
  }
  .faq-landing-title-container p{
    font-size: 28px;
  }
}