.opacityImage-background {
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
}

.opacityImage-background .container-fluid .container {
  padding-top: 20%;
  min-height: 94vh;
}
.opacityImage-h1 {
  font-size: 38px;
  padding-bottom: 10px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-family: 'Roboto', Arial, sans-serif !important;
  font-weight: 300 !important;
  margin: 0px;
}

.opacityImage-h2 {
  font-size: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-family: 'Roboto', Arial, sans-serif !important;
  color: #d90e0e !important;
  font-weight: 300 !important;
  margin-bottom: 28px;
}

.opacityImage-h6 {
  padding: 0px 144px 20px 0px;
  font-family: 'Roboto', Arial, sans-serif !important;
  letter-spacing: 0 !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 1.5em !important;
  color: #666;
  margin-bottom: 29px;
}

.opacityImage-btn {
  padding: 12px 30px;
  font-size: 18px;
  font-weight: 500;
  fill: #333333;
  color: #333333;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-radius: 40px 40px 40px 40px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.opacityImage-btn:hover {
  fill: #333333;
  color: #333333;
  background-color: #ffffff;
  text-decoration: none;
}

.Opacity-mobile-background .container-fluid {
  padding: 0px;
}
.Opacity-mobile-content {
  text-align: center;
  padding-top: 10%;
  padding-bottom: 10%;
}
.Opacity-Mobile-Img {
  width: 100%;
}
@media only screen and (max-width: 780px) {
  .opacityImage-h1 {
    font-size: 28px !important;
    font-family: 'Roboto', Arial, sans-serif !important;
    letter-spacing: 0 !important;
    font-weight: 300 !important;
    padding: 0px;
    margin: 0px;
    text-transform: unset;
  }

  .opacityImage-btn {
    font-size: 12px;
    font-weight: 500;
    fill: #333333;
    color: #333333;
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-radius: 40px 40px 40px 40px;
  }
  .opacityImage-h6 {
    padding: 0px;
    
  }
  .opacityImage-h2 {
    font-family: 'Roboto', Arial, sans-serif !important;
    letter-spacing: 0 !important;
    font-weight: 300 !important;
    font-size: 20px !important;
    text-transform: unset;
  }
}
