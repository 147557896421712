.inputdiv {
    input {
    max-width: 25% !important;
    @media (max-width: 660px) {
        max-width: 100% !important;
    }
    }
}

.gridstyle {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.css3customformdiv {
    
    margin: 0 20px 0 0;
    @media (max-width: 660px) {
    margin: 10px 5px!important;
    }

}


input[type=checkbox] {
    padding: 0px !important;
    outline: none;
}

.css3Input {
    input:focus {
        border: 1px solid #999 !important;
        
    }
}

.checkBoxErrorMessage{    
    display: block;
    color: #990000;
    font-size: 12px;
    float: none;
    cursor: default; 
}


.inputdiv60percent {
    max-width: 60%;
}