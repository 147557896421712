.GroupCardProductSale {
  margin: 50px 0px;
  .GroupCardProductSale-h2 {
    font-size: 38px;
    margin-bottom: 0;
    padding-bottom: 10px;
    font-weight: 500 !important;
    line-height: 1.2em;
    text-align: center;
  }

  .GroupCardProductSale-h6 {
    font-size: 16px;
    line-height: 1.5em;
    color: #666;
    font-weight: 400;
    padding: 0px 20px;
    text-align: center;
  }

  .GroupCardProductSale-felxbox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  .app-card {
    height: 100%;
    box-shadow: 0px 2px 30px 0px #00000042;
    padding: 20px 0px;
  }

  .CardProductSale-Container {
    height: 895px;
    margin: 10px 0px;
  }

  .CardProductSale-flexbox-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .CardProductSale-flexbox-content div {
    margin-bottom: 20px;
  }

  .CardProductSale-img {
    max-width: 100%;

    height: 208px;
    background-size: cover;
  }

  .CardProductSale-h5 {
    margin: 0px;
    background-color: #231f20;
    color: #ffffff;
    width: 100%;
    text-align: center;
    padding: 2% 0;
  }

  .CardProductSale-h4 {
    color: #de0000;
    text-align: center;
  }

  .cardproduct-button {
    border-width: 0px;
    background: #de0000;
    font-size: 20px;
    font-weight: 400;
    border-radius: 40px;
    padding: 15px 30px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }

  .cardproduct-button:hover {
    box-shadow: none;
    text-decoration: none;
    color: white;
  }

  .cardproduct-h3-style {
    text-align: center;
  }
  .cardproduct-h3-style-price {
    color: #de0000;
  }
  .card-product-you-save{
    text-align: center;
    color: #de0000;
  }

  .card-product-you-save strong{
    color: #de0000;
  }

  .cardproduct-h3-style-no-discount {
    color: black;
  }

  .whiteglove-alert-h6 {
    font-weight: bold;
    color: #de0000;
  }

  .cardproductsale-text-include {
    text-align: center;
    font-weight: bold;
    color: #222;
  }

  .cardproduc-tooltip-h6 {
    color: #e09900;
  }

  .cardproduc-tooltip-h6-center {
    text-align: center;
  }

  .cardproduct-h3-strick {
    text-align: 'center';
    text-decoration: line-through;
    font-size: 18px;
  }

  .show-new-price{
    color: black;
  }

  .hide-new-price{
    color: white;
  }

  .CardProductSale-price-card{
    height: 68px;
  }
  // ---------------tools tips start-----------
  .tooltips {
    position: relative;
    display: inline-block;
    color: #fff;
    background: #666;
    padding: 5px 10px;
    border-radius: 60px;
    font-size: 14px;
    line-height: 12px;
  }

  .tooltips .tooltiptexts {
    visibility: hidden;
    width: 310px;
    background-color: #eee;
    color: #333;
    text-align: center;
    border-radius: 6px;
    padding: 30px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -250px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
  }

  .tooltips .tooltiptexts::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 81%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }

  .tooltips:hover .tooltiptexts {
    visibility: visible;
    opacity: 1;
  }

  //-----------------tool tipned -------------
  .CardProducctSale-flexbox2-include {
    padding-top: 5%;
    border-top: 1px solid #999;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .CardProducctSale-flexbox2-include div {
    padding-bottom: 10px;
  }

  .CardProductSale-include-h6 {
    font-size: 16px !important;
    line-height: 1.5em !important;
    color: #666;
    text-align: center;
    font-weight: 400;
  }

  .CardProductSale-affirm {
    color: #666;
    a {
      color: #e09900;
    }
    a:hover {
      text-decoration: none;
      color: #e0ba67;
    }
  }

  @media only screen and (max-width: 525px) {
    .GroupCardProductSale-h2 {
      font-size: 20px;
      line-height: 1.2em;
      letter-spacing: .2em !important;
      font-weight: 500 !important;
    }
  
  }
}

.free-shipping-bundles{
  background-color: #d3222a;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  padding: 10px;
  text-align: center;
}

.n3-elite-bundles-container{
  padding: 0px 40px;
  margin-top: 3em;
}

.n3-elite-bundles-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.n3-elite-bundles-wrapper h1{
  font-size: 48px;
  font-weight: 400;
  text-align: center;
}

.n3-desp-wrapper{
  text-align: center;
    color: #000000de;
    font-size: 16px;
    width: 735px;
    max-width: 735px;
}

.n3-desp-wrapper p{
  text-align: center;
    color: #000000de;
    font-size: 16px;
    margin-bottom: 0;
}

.n3-products-container {
  max-width: 1000px;
  margin: auto;
  margin-bottom: 4em;
}

.n3-best-seller {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 100%;
    background: #d3222a;
    color: #fff;
    padding: 7.5px 10px;
    border-radius: 12.5px 12.5px 0 0;
}

.n3-products-row{
  display: flex;
}

.n3-products-col {
  width: 50%;
  margin: 3em 0 0;
    padding: 10px;
}

.n3-products-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 3px 2px rgb(0 0 0 / 30%);
    border-radius: 6px;
}

.best-seller-n3-border {
  border: 2px solid #d3222a;
}

.n3-best-seller i{
  font-size: .5em;
  margin: .25em;
}

.n3-best-seller span {
  margin: 0 .5em;
}

.n3-products-iamge-wrapper {
}

.n3-products-iamge-wrapper img{
  width: 100%;
    max-height: 250px;
    object-fit: contain;
}

.n3-products-title-wrapper{
  margin-bottom: 20px;
  padding: 20px;
  background-color: #231f20;
  width: 100%;
}

.n3-products-title-wrapper h3{
  width: 100%;
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: .1em;
  margin-bottom: 0;
  text-align: center;
}

.n3-products-price-wrapper {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.n3-products-price-wrapper span {
  font-size: 34px;
  font-weight: 400;
  color: #333333;
}

.n3-you-save {
  font-size: 20px !important;
    color: #d3222a !important;
}

.n3-bundle-finance .affirm-as-low-as {
  font-size: 0;
}

.n3-bundle-finance .affirm-as-low-as a::after {
  content: "Financing Options Available";
  display: block;
  font-size: 16px;
  line-height: 2em;
  text-decoration: underline;
  color: #d3222a;
}

.CardProductSale-affirm .affirm-as-low-as {
  font-size: 16px;
    // color: #d3222a;
}

.n3-bundle-finance {
  margin-bottom: 20px;
}

.n3-actual-price-bundles {
  font-size: 20px !important;
  text-decoration: line-through;
}

.n3-products-price-wrapper p {
  font-size: 16px;
  line-height: 1.5em;
  color: #666;
  margin-bottom: 0;
}

.update-button-wrapper a button{
  padding: .6em 1em;
  background: #d3222a;
}

.white-glove-av{
  margin-bottom: 20px;
  color: #000000bc;
    font-size: 13px;
}

.divider-wrapper{
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  width: 100%;
}

.divider-wrapper div{
  width: 33%;
  border-top: 1px solid black;
  margin: auto;
}

.div-feature-includes-wrapper {
  margin: 0 0 3em;
}

.div-feature-includes-wrapper h4{
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.div-feature-includes {
  list-style-type: none;
  // display: flex;
  // flex-direction: column;
}

.div-feature-includes li {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 18px;
  line-height: 1.25;
  color: #84888b;
  text-align: center;
}

  // ---------------tools tips start-----------
  .tooltips {
    position: relative;
    display: inline-block;
    color: #fff;
    background: #666;
    padding: 5px 10px;
    border-radius: 60px;
    font-size: 14px;
    line-height: 12px;
    margin-left: 5px;
  }

  .tooltips .tooltiptexts {
    visibility: hidden;
    width: 310px;
    background-color: #eee;
    color: #333;
    text-align: center;
    border-radius: 6px;
    padding: 30px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -250px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
  }

  .tooltips .tooltiptexts::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 81%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }

  .tooltips:hover .tooltiptexts {
    visibility: visible;
    opacity: 1;
  }

  @media (max-width: 979px) {
    .n3-products-col {
      width: 100%;
    }
    .n3-products-row {
      flex-wrap: wrap;
  }
}

@media (max-width: 750px) {
  .n3-desp-wrapper {
    max-width: 100%;
  }
}