.mini-widget-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    background: transparent;
    height: 70vw;
    max-height: 90vh;
}

.mini-widget-img-container{
    width: 100%;
    height: 100%;
    position: relative;
}

.main-bg-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

.widget-review-title-container{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
}

.item-bottom-wrapper h1{
    font-size: 28px;
    padding-bottom: 10px;
    margin-bottom: 30px;
}

.icons-container div h4{
    font-size: 27px;
    font-weight: 300;
}

.item-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 20px;
}

.sub-title-container h2{
    font-size: 34px;
    font-weight: 500;
    line-height: 1.25;
    margin-bottom: 0;
}

.sub-title-container h2 p{
    margin-bottom: 0;
    z-index: 3;
}


.p-subtitle{
    font-size: 16px;
    font-weight: 400;
}

.feedback-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.item-bottom-wrapper{
//  max-width: 1100px;  
//  margin: 0 auto;
}

.review-icons{
    height: 75px;
    width: auto;
    object-fit: scale-down;
    object-position: center center;
}

.icons-container{
    margin: 0 60px;
}

.display-mb{
    display: none;
}

@media (max-width: 979px){
    .item-wrapper{
        padding: 0px 10px 10px;
    }
    .icons-container{
        margin: 0 3vw;
    }
    .item-bottom-wrapper h1{
        font-size: 16px;
        margin-bottom: 0;
    }
    .sub-title-container{
        position: relative;
        width: 100%;
        padding-top: 30px;
    }
    .sub-title-container::before{
        position: absolute;
        content: "";
        left: 0;
        top: 0px;
        height: 40%;
        width: 100%;
        background: linear-gradient(black, transparent);
        z-index: 1;
        pointer-events: none;
    }
    .review-icons{
        height: 10vw;
        max-height: 60px;
    }
    .icons-container div h4{
        font-size: 13px;
    }
    .item-bottom-wrapper{
        max-width: 100%;
       }
    .mini-widget-card-container{
        height: 550px;
    max-height: 95vh;
    }
    .display-lg{
        display: none;
    }
    .display-mb{
        display: block;
    }
}