.widget-container{
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 45px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.widget-container::before{
    content: url("https://prodnexersyswebsiteblob.blob.core.windows.net/assets/Hexagon_BLK_5_80c9098e64.svg");
    position: absolute;
    left: 0;
    bottom: 0;
}

.widget-container::after{
    content: url("https://prodnexersyswebsiteblob.blob.core.windows.net/assets/Hexagon_BLK_5_80c9098e64.svg");
    position: absolute;
    right: 0;
    top: 0;
}

.widget-thumbnail{
    clip-path: circle(40%);
    max-width: 300px;
}

.widget-img{
    height: auto;
    max-width: 100%;
    transform: scale(1.5) translate(5%, 12%);
    object-fit: cover;
    object-position: center center;
}

.testimony-body-container{
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.body-max-width{
    max-width: 700px;
}

.testimony-body{
    font-size: 28px;
    line-height: 1.25;
    position: relative;
}

.testimony-body::before{
    content: "“";
    position: absolute;
    bottom: 100%;
    left: 0;
    display: block;
    font-size: 100px;
    color: #D3222A;
    margin-bottom: -60px;
}

.testimony-review{
    margin-top: 10px;
    font-size: 20px;
    line-height: 1.5;
}

.testimony-name{
    font-weight: 500;
    color: #D3222A;
}

.widget-container-landing{
    // justify-content: space-between;
    // padding: 0;
    margin-left: 30%;
}

.widget-container-landing .widget-thumbnail{
    position: absolute;
    clip-path: circle(42%);
    left: -50%;
}

// .widget-container-landing .widget-thumbnail{
//     clip-path: circle(42%);
//     margin-left: -50px;
// }

.widget-container-landing .testimony-body-container {
    // margin-left: 10%;
    // margin-top: 0;
    text-align: left;
}

.widget-container-landing .testimony-body, .testimony-review {
   color: white
}

.widget-container-landing .testimony-review {
    margin-top: 30px;
}

.widget-container-landing .testimony-name{
    color: #fc5310;
}

.widget-container-landing .testimony-body::before{
    bottom: 0;
    left: -49px;
    color: #fc5310;
    margin-bottom: 15px;
    top: -30%;
}

@media (max-width: 979px){
    .widget-container-landing .widget-thumbnail{
        left: -60%;
    }
}

@media (max-width: 735px){
    .widget-container{
        flex-direction: column;
        max-width: 375px;
        // margin: 30px auto;
        padding: 0 40px;
        overflow: hidden;
        }
    .testimony-body-container{
        align-items: center;
        text-align: center;
    }
    .testimony-body::before{
       display: none;
    }
    .widget-thumbnail {
        -webkit-clip-path: circle(40%);
        clip-path: circle(40%);
        max-width: 230px;
    }
    .widget-container::after {
        right: 7%;
    }
    .testimony-body-container {
        margin-top: 15px;
    }
    .widget-container-landing .testimony-body{
        font-size: 20px;
    }
    .widget-container-landing{
        padding: 0;
        margin-top: 40px;
        margin-bottom: 0;
        margin-left: 0;
    }
    .widget-container-landing .testimony-body-container{
        text-align: center;
    }
    .widget-container-landing .widget-thumbnail {
        position: static;
        margin-left: 0px;
    }
    .widget-container-landing .testimony-review{
        margin-top: 10px;
    }
}