.checkout-table {
 
  // border: 1px solid rgba(0,0,0,.125);
  // border-radius: .25rem;
  // background-color: #ededed;
  

  .checkouttable-product-header {
    // padding: 20px 0 !important;
    border: 0;
    // background-color: rgba(0, 0, 0, 0.03);
    font-weight: 700;
    line-height: 1.5em;
    border: none !important;
    color: #666;
    &.coupon {
      background-color: #e02b20;
      color: #fff;
    }
  }

  .checkout-totale-amount {
    // padding: 20px !important;
    vertical-align: middle;
    line-height: 1.5em;
    border: none !important;
    font-weight: 700;
    color: #666;
    &.coupon {
      background-color: #e02b20;
      color: #fff;
    }
  }

  .checkout-body-total {
    // background-color: rgba(0, 0, 0, 0.005);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    padding: 20px !important;
    border: 0;
    vertical-align: middle !important;
    line-height: 1.5em;
    font-weight: 400;
    color: #666;
  }

  .checkout-body-bundle-total {
    // background-color: rgba(0, 0, 0, 0.005);
    padding: 20px !important;
    border: none !important;
    vertical-align: middle !important;
    line-height: 1.5em;
    font-weight: 400;
    color: #666;
  }
  .display-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .checkout-image {
    max-width: 200px;
    max-height: 200px;
    @media only screen and (max-width: 768px) {
      max-width: 75px;
      max-height: 75px;
    }
  }
  .checkout-page-item {
    text-align: center;
    color: #666;
  }

  .checkout-tr-noborder {
    text-align: center;
    color: #666;
    border: none !important;
  }
}


.productdetail {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .pagedetailproduct {
    font-weight: 700;
    color: #666;
    padding: 10px;
  }
}
.productdisplay {
  p {
  color: #666;
  border: none !important;
  // padding-left: 70px;
  }
}

.productdetailcontainer {
  //  border-bottom: 1px solid grey;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  background-color: #f7f7f7;
  padding: 20px;
  
}

.pd_5 {
  padding: 5px 0;
}