.product-review-widgt {
    background-color: transparent;
    background-image: linear-gradient(
45deg,#000000 0%,#4D242C 100%);
padding: 10px;
  }

  .product-review-widgt-max {
    .container-fluid {
      max-width: 1600px;
    }
  }

  .col-review{
    padding: 10px 30px;
  }

  .carousel-col-review{
    padding: 45px;
  }
  

  .first-comment {
    max-width: 270px;
  }

  .review-title{
    font-size: 20px;
    line-height: 1.25;
    // max-width: 280px;
    margin: auto;
    margin-bottom: 1em;
    color: white;
    text-align: center;
    // padding: 15px 30px;
  }


.review-description {
    font-size: 14px;
    color: white;
    text-align: center;
    margin: auto;
}

.reviewer-name {
    color: #EC2524;
}

.review-container{
    // font-size: 1rem;
    padding: 10px;
}

.review-container-slider{
    max-width: 66%;
    max-width: 375px!important;
    margin: auto;
    opacity: 87%;
  }

  .slick-next{
      right: 5px;
      z-index: 1;
  }

  .slick-next:before,
.slick-prev:before {
  content: "" !important;
}

.right-arrow-icon{
  font-size: 20px;
  color: hsla(0,0%,93.3%,.9);
  cursor: pointer;
}


//   .slick-next::before{
//     content: ">";
//     color: white;
//     display: inline-block;
//     width: .8em;
//     text-align: left;
// }

  .slick-prev {
    left: 5px;
    z-index: 1;
}