.Manual-Background {
    padding: 0% 0% 3% 0%;
  .Manual-flex {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin: 17px;
   
  }
  .Manual-h4 {
    font-size: 24px !important;
    padding-bottom: 10px;
    font-weight: 500 !important;
    line-height: 1.2em;
    text-align: center;
    color: #333333;
    margin-bottom: 20px;
  }
  .Manual-button {
    fill: #ef4a43;
    color: #ef4a43;
    background-color: rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #ef4a43;
    border-radius: 40px 40px 40px 40px;
    font-weight: 400;
    font-size: 15px;
    padding: 12px 24px;
    transition: all 0.3s;
  }
  a{
      text-decoration: none;
  }
}



@media only screen and (max-width: 640px) {
  .Manual-Background {
      
    .Manual-h4 {
      font-size: 16px !important;
     
    }
  }
  
}

