.app-pagination {
  list-style-type: none;
  display: flex;
  padding: 0;
  justify-content: center;
  user-select: none;
  li {
    margin: 0px 6px;
    cursor: pointer;
    color: #e09900;
    &:hover {
      color: #e0ba67;
    }
    &.active {
      color: black;
      &:hover {
        color: black;
      }
    }
    &.disable {
      cursor: not-allowed;
      color: black;
      &:hover {
        color: black;
      }
    }
    &.rest {
      color: black;
      &:hover {
        color: black;
      }
    }
  }
}
