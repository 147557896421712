.Affirm-checkout-button{
    margin-top: 20px !important;
    float: none;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 700;
    font-size: 150%;
    background-color: #eee !important;
    border: none !important;
    color: #666 !important;
}

.Affirm-checkout-button-amazon{
    margin-top: 20px !important;
    float: none;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 700;
    font-size: 150%;
    background-color:  #de0000 !important;
    border: none !important;
    color: white !important;

}