.imagecontainer {
    width: 150px;
    height: 189px;
    // padding: 10px 30px;

    img {
        width: 100%;
        height: 100%;
    }
}
.checkboxitem {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    margin: 10px 30px;
}

.checkboxformlabel {
    font-size: 25px!important;
    font-weight: normal!important;
    margin: 30px 0!important;

    @media (max-width:767px) {
        text-align: center;
        width: 100%;
        margin: 20px 0 !important;
    }
}

.N3LeadFormAccordion-body {
    padding: 20px 30px;
    background-color: #f2f2f2;
}

input[type='radio'] {
    padding: 0 !important;
}

.inputdimension {
    @media (min-width:768px) {
        max-width: 60%;
    }
   
}

.n3formsubmitButton {
    border-radius: 60px;
    background-color: #de0000!important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px!important;
    font-weight: 500;
    padding: 10px 60px!important;
    cursor: pointer;
    outline: none !important;
    color: #fff!important;
    border: none;
    @media (max-width:768px) {
        padding: 0;
    }

}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 35px;
}
.error-message-astrid-show {
    color: red !important;
    margin-left: 5px !important;
  }

//   .container {
//       @media (max-width:768px) {
//           padding: 0!important;
//       }
//   }

.n3lead-body {
    margin-top: 0%;
}

.radiocheckbox {
    padding-left: 0px !important;
    margin: 25px 0px;
    @media (max-width:764px) {
        margin: 15px 0px;
    }
}

.form-check-input.is-invalid, .was-validated .form-check-input:invalid {
    border: 1px solid rgba(0,0,0,.25) !important;

}
.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
    color: #212529 !important;
}

.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
     background-color: blue;
}

.form-check-input.is-valid, .was-validated .form-check-input:valid {
    border-color:rgba(0,0,0,.25)
}