.css1email {
    input[type=email] {
        margin: 0px 0 10px!important;
    }
}
.css1Formlabel {
    font-size: 20px!important;
    font-weight: 400!important;
    margin: 20px 0 10px!important;
    @media (max-width:767px) {
        text-align: center;
        width: 100%;
        margin: 20px 0 !important;
    }
    @media (max-width: 660px){
    font-size: 17px!important;
    font-weight: 700!important;
    margin: 25px 0 0!important;
    text-align: justify;
    line-height: 1.3;
   }
}

.css1selectinput {
    max-width: 25%;
}