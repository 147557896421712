.widgetplayer-overlay {
  background-color: black;
  opacity: 0.7;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
}
.widget-player {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-player > body {
  overflow: hidden;
}

.widget-player > span {
  color: white;
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 3;
  cursor: pointer;
}

.widget-player {
  .widget-video {
    width: 50%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    outline: none;
    video {
      width: 100%;
    }
    & span {
      color: white;
      font-size: 18px;
      font-weight: 600;
      position: absolute;
      right: -20px;
      top: -40px;
      z-index: 3;
      cursor: pointer;
    }
    @media only screen and (max-width: 990px) {
      width: 100%;
      height: auto;
      video {
        width: 100%;
      }
    }
  }
}
