.playermain {
  width: 100%;
  .player {
    // width: 100%;
    // height: 100%;
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
  }
}

.image-video-player {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: 80vh;
  .overlay {
    background-color: #000000;
    opacity: 0.35;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
  .player {
    width: 100%;
    height: 100%;
    object-fit: cover;
    &.img {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
  .content {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      color: white;
      font-size: 54px;
      line-height: 1em;
      letter-spacing: 0em;
    }
    h2 {
      color: white;
      letter-spacing: 0.1em;
    }
    p {
      color: white;
      cursor: pointer;
      text-decoration: underline;
      animation: flash linear 3s 2;
      font-weight: 400;
      font-size: 20px;
      text-transform: capitalize;
      .playerIcon {
        margin-left: 5px;
      }
    }
    a {
      text-decoration: none;
    }
    .button {
      text-transform: capitalize;
      background-color: #de0000;
      text-align: right;
      margin-right: 0;
      border-radius: 40px;
      color: #fff;
      padding: 13px 30px;
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 768px) {
    .image-video-player {
      height: 600px;
      background-color: #2d2c2c;
    }
    .content {
      h1 {
        font-size: 28px !important;
      }
      h2 {
        font-size: 20px !important;
      }
    }
  }
}

.overlay-video{
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.4);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.overlay-container{
  text-align: center;
  position: relative;
  width: 100%;
  text-shadow: 1px 1px 10px rgba(0,0,0,0.75);
}

.overlay-h1{
  font-size: 4em;
  font-weight: 500;
  color: white;
  margin: 5px;
  text-transform: uppercase;
}

.overlay-p {

  color: white;
  text-align: center;
  margin: 0;
  font-size: 1.2em;
}

.off-center {
  justify-content: left;
  .overlay-container .overlay-h1{
    text-shadow: 1px 1px 10px rgba(0,0,0,0.75);
    font-size: 35px;
    margin-left: 20%;
    width: 100%;
  }
}

.overlay-text{
  color: white;
}

.show-relative{
  position: relative;
  height: calc(95vh - 60px);
}

.show-mute-button{
  position: absolute;
    bottom: 0;
    color: white;
    right: 0;
    margin: 13px;
}

@media (max-width: 979px){
  .overlay-video{
    align-items: flex-end;
  }

  .overlay-text{
    margin-bottom: 0.5rem;
  }

  .overlay-p {
    margin-bottom: 2rem;
  }  

  .off-center {
    justify-content: center;
    .overlay-container .overlay-h1{
      font-size: 25px;
      margin: 5px;
      width: 100%;
    }
  }
}

@media (max-width: 900px){
  .show-relative {
    height: calc(90vh - 60px);
  }
}

@media (max-width: 480px){
  .overlay-h1{
    font-size: 34px;
  }
}
