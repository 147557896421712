.trainerImageCard-background-black{
    background-color: black;
    padding: 30px;
    // p{
    //     margin-bottom: 0;
    // }
}

.trainerImageCard-containerfluid{
    max-width: 1600px;
    margin: auto;
}

.trainerImageCard-flex-container-landing {
    text-align: center;
    padding-top: 10px;
}

.trainerImageCard-title{
    color: white;
}

.trainerImageCard-box{
    height: 400px;
    position: relative;
}

.trainer-col{
    padding: 30px;
}

.trainerImageCard-box-img{
    background-repeat: no-repeat;
    background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
}

.trainerImageCard-center{
    text-align: center;
    position: absolute;
    bottom: 0;
}

.trainerImageCard-center-h3{
    font-size: 20px;
    font-weight: 500;
    color: white;
}

.trainerImageCard-center-text {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.25em;
    color: white;
}

@media (max-width: 480px){
    .trainerImageCard-background-black {
        padding: 0;
    }
    .trainer-col {
        padding: 0;
    }
    .remove-card-margins{
        margin: 0;
    }
}