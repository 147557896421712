.VideoWidgetItem {
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-image-container {

  .center-ing {
    justify-content: center;
    flex-direction: row-reverse;
  }
}
.videoimage-container {
  position: relative;
  padding: 50px 0px;
  background-size: 0 0;
  overflow: hidden;
}

.hexBottomLeft {
  position: absolute;
  transform: scale(2);
  left: 1%;
  bottom: 12%;
  @media screen and (max-width: 990px){
    display: none;
  }
}

@media only screen and (max-width: 981px) {
  .videoimage-container {
    padding: 15px 0px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .video-image-container {
    .center-ing {
      flex-direction: column-reverse;
    }
  }
}

@media only screen and (min-width: 981px) {
  .videoimage-container {
    background-image: none !important;
  }
}
