
.ContactFormWidget-container {
    .ContactFormWidget-flexbox-content {
  padding-top: 5%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    .ContactFormWidget-h2{
        letter-spacing: .1em !important;
        box-sizing: border-box; 
        font-size: 38px !important;
        padding-bottom: 10px;
        font-weight: 500 !important;
        line-height: 1.2em;
    }
    .ContactFormWidget-h6{
        font-size: 16px !important;
        line-height: 1.5em !important;
        color: #666;
        font-weight: 400;
    }  
}