.mycss {
    display: flex;
    flex: 0 0 45%;
    flex-direction: row;
   
}
.inputContent {
    padding: 20px 14.5px;
    @media (max-width: 767px) {
        padding: 15px 0;
    }
    @media (max-width: 425px) {
        margin: auto;
    }
}

.submitbutton {
    display: inline-block;
    font-size: 18px!important;
    font-weight: 500!important;
    letter-spacing: 1.87px!important;
    text-transform: uppercase;
    padding: 0.6em 3.5em!important;
    background: #d3222a!important;
    color: white!important;
    box-shadow: 0 2px 4px rgba(0,0,0,0.6)!important;
    border: none!important;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    background-position: center;
    transition: background 0.8s;
}

.form-control:focus {
    border: 1px solid #de0000!important;
    box-shadow: none !important;
}
input {
    padding: 6px 10px !important;
}

input:focus {
    border: 1px solid #de0000!important;
}
.zipcodeStyle {
    flex: 0 0 15%;
}

.phoneNumberStyle {
    flex: 0 0 27.5%;
}

.tshirt-background {

}

// .form-control.is-valid, .was-validated .form-control:valid {
//         border: 1px solid #de0000!important;
//         padding-right: calc(1.5em + .75rem);
//         background-image: none;
//         background-repeat: no-repeat;
//         background-position: right calc(.375em + .1875rem) center;
//         background-size: calc(.75em + .375rem) calc(.75em + .375rem);
//     }

.tshirtFormBackground {
    padding: 60px 30px 30px 30px;
    margin-right: 81px;
    @media (max-width: 428px) {
        padding: 45px 15px;

        margin-right: 0px;

    }
}


.marginright {
  
    @media(max-width: 425px) {
        margin-right: 0px;
    }
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    /* border-color: #dc3545; */
    border-color: rgb(206, 212, 218) !important; 
    background-image:none !important;
   
}

.validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: none;
    background-position: right .75rem center,center right 2.25rem;
    background-size: 16px 12px,calc(.75em + .375rem) calc(.75em + .375rem);
}
.form-select.is-invalid, .was-validated .form-select:invalid {
    /* border-color: #dc3545; */
    border-color: rgb(206, 212, 218) !important; 
}
.form-control.is-valid, .was-validated .form-control:valid {
    border-color: rgb(206, 212, 218) !important; 
   
}

.form-control.is-valid, .was-validated .form-control:valid {
    border-color: rgb(206, 212, 218) !important; 
    background-image: none !important;
}