.app-blog-listing {
  .app-blog-type-title {
    display: flex;
    margin-bottom: 20px;
    @media only screen and (max-width: 768px) {
      h1 {
        font-size: 28px;
      }
    }
    img {
      margin-right: 20px;
      border-radius: 50%;
      width: 50px;
      height: 50px;
    }
  }
}
