.radioInput {
    opacity: 0;
    width: 0;
    position: absolute;


}


.radioOtionContainer {
    display: flex;

    .radioInputContet {
        max-width: 150px;
        margin: 5px;

    }
    .radioInputContet img {
        max-width: 100%;
    }

}
input[type=radio]:checked + label>img {
    border: 2px solid #de0000!important; 
    border-radius: 5% !important;
    
}

input[type=radio]:checked + label>svg {
    fill: #de0000;
} 


.leave-productoverview-star {
    color:red !important;
    transform: scale(1.2);
    margin-right: 5px;
  }



.ratingContainer {
    display: flex;
}


.leaveInputContent {
    padding: 10px 0 !important;
}

.marginbottom {
    margin-bottom: 0px !important;
}


  .notestyle {
    order: 2;
    margin-top: 0;
    color: #777;    
    font-size: 13px;
    line-height: 1.3;
   
  }


  
    .leavesubmitbutton {
        display: inline-block;
        font-size: 18px!important;
        font-weight: 500!important;
        letter-spacing: 1.87px!important;
        text-transform: uppercase;
        padding: 0.6em 3.5em!important;
        background: #d3222a!important;
        color: white!important;
        box-shadow: 0 2px 4px rgba(0,0,0,0.6)!important;
        border: none!important;
        outline: none;
        border-radius: 5px;
        cursor: pointer;
        background-position: center;
        transition: background 0.8s;
       
    }
    

.leavereviewformbackground {
    @media (max-width: 768px){
        padding: 15px !important;

    }
}
.leavereviewfiles input {
    outline: none;
    outline-offset: -10px;
    -webkit-transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    height: 150px;
    z-index: 7;
    position: relative;
    opacity: 0;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
  }
  .leavereviewfiles input:focus {
    outline: none;
    outline-offset: -10px;
    -webkit-transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    border: 1px solid #b39292;
  }

  .leavereviewfiles {
    border: 1px dashed #ccc;
    border-radius: 2px;
    padding: 20px;
    position: relative;
    input {
      cursor: pointer;
    }
    .app-file-upload-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      position: absolute;
      top: 0;
      width: 100%;
      height: 150px;
      z-index: 4;
     
    }
  }
  .leavereviewfiles:after {
    pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 50px;
    right: 0;
    height: 56px;
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .color input {
    background-color: #f1f1f1;
  }
  .leavereviewfiles:before {
    position: absolute;
    bottom: 10px;
    left: 0;
    pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    display: block;
    margin: 0 auto;
    color: #a52e2e;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
  }

  .app-selcted-image {
    position: relative;
    border-bottom: 1px solid #f1f1f1;
    .app-clear {
      position: absolute;
      top: 0;
      right: 5px;
      z-index: 10;
      cursor: pointer;   
    }
    .app-clear:hover {
      opacity: 1;
    }
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    
    position: relative;
    .app-image-preview {
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .app-details {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      word-break: break-word;
      flex: 1;
      margin-right: 25px;
    }

    .app-details span:first-child {
      font-size: 12px;
    color: #888;
    }
  }

  .placeholderContent {
    color: rgba(0,0,0,0.4) !important;
  }

  .validationcontent {
    order: 3;
    color: #777;
    font-size: 0.6em;
  }