.app-rating-popup {
  background-color: #00000087;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2147483640;
  .form-control-con {
    margin-bottom: 15px;
  }
  .error-message {
    color: #dc3545;
    display: inline-block;
    font-size: 13px;
  }
  .error-field {
    border: 1px solid #dc3545;
  }

  .app-rating-container {
    min-width: 300px;
    height: 100%;
    position: relative;
    padding: 30px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .app-rating-content {
      background-color: #fff;
      position: relative;
      .app-close-icon {
        color: white;
        font-size: 18px;
        font-weight: 600;
        position: absolute;
        right: 20px;
        top: 0px;
        cursor: pointer;
        z-index: 1;
      }
      .app-content {
        max-height: 90vh;
        width: 300px;
        min-width: 300px;
        // min-height: 300px;
        max-width: 768px;
        background-color: #fff;
        position: relative;
        overflow-y: auto;
        @media only screen and (min-width: 768px) {
          width: 768px;
        }
        @media only screen and (max-width: 768px) and (min-width: 552px) {
          width: 552px;
        }

        .app-review-form {
          padding: 30px 10%;
          .app-form-title {
            font-size: 120%;
            font-weight: 700;
            display: block;
            margin-bottom: 10px;
          }
          .app-review-stars-container {
            margin: 0px 0px 10px 0px;
          }
          input.form-control {
            margin-bottom: 10px;
          }
          textarea.form-control {
            margin-bottom: 10px;
          }
          input[type='submit'] {
            width: 115px;
            margin: 30px 0 0;
            background-color: #de0000;
            border-radius: 50px;
            text-transform: capitalize;
            font-size: 20px;
            font-weight: bold;
            color: white;
          }
        }
        .app-rating-footer {
          margin: 10px 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .app-see-more {
            color: #de0000;
            display: block;
            margin: auto;
            margin: 0.7em 0px;
            text-decoration: underline;
            cursor: pointer;
          }
          .app-add-review {
            color: #de0000;
            display: block;
            margin: auto;
            padding: 0.25em;
            text-decoration: underline;
            font-weight: bold;
            cursor: pointer;
          }
        }
        .app-rating-data {
          padding: 15px 30px;
          .app-stars-container {
            margin: 10px 0px;
            .app-star-spacing {
              margin-right: 3px;
              color: #de0000;
            }
          }
          .app-rating-name {
            font-weight: 500;
            color: #767676;
          }
          .app-rating-des {
            line-height: 1.5em;
            color: #666;
          }
        }
        .app-no-rating {
          height: 200px;
          background-color: #f3f3f3;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #666;
        }
      }
    }
  }
}
