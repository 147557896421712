// ----- Variables -----
$tablet-break: 990px;


// ----- Styling -----

.training-section {
  position: relative;
  // background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(225,231,233,1) 100%);
  

  .bottomLeftHex{
    position: absolute;
    bottom: -3%;
    left: -1.5%;
    width: 175px;
    @media screen and (max-width: 990px) {
      display: none;
    }
  }
}

.training-container {
  position: relative;
  height: auto;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4%;
  padding-bottom: 4%;
}

    // Columns
.description-col {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  position: relative;
  text-align: center;
  padding-top: 15%;

  h2 {
    font-weight: 300;
    font-size: 2em;
  }

  h4 {
    font-size: 15px;
    color: #7A7A7A;
    margin-bottom: 1rem;
  }

  ul {
    list-style: inside;
    padding-top: 20px;
    padding-left: 0;
    font-size: 14px;
    color: #7A7A7A;
    li {
      padding-bottom: 10px;
    }
  }

  grid-column: span 6;
  @media screen and (max-width: $tablet-break) {
    grid-column: span 12;
    padding-top: 10%;
    padding-left: 0;
  }
}

.video-col {
  display: flex;
  flex-flow: column;
  grid-column: span 6;
  @media screen and (max-width: $tablet-break) {
    grid-row: 1;
    grid-column: span 12;
  }
}

  // Flair
  .flair-image-dt {
    position: absolute;
    left: 0;
    top:0;
    // transform: scale(0.80);
    max-width: 125px;
    @media screen and (max-width: $tablet-break){
      display: none;
    }
  }

  .flair-image-mb {
    padding-top: 5%;
    padding-bottom: 5%;
    align-self: center;
    width: 150px;
    display: none;
    @media screen and (max-width: $tablet-break){
      display: block;
    }
  }



    // Icons
.icon-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    @media screen and (max-width: 500px){
      max-width: 340px;
    }
    margin: 0 auto;
}

.icon-card-container {
    display: flex;              
    flex-direction: column;    
    justify-content: center;  
    align-items: center;       
    padding-left: 10px;
    padding-right: 10px;
    p {
        margin-bottom: 10px;
    }

}

.icon-container {
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 55px;
      max-height: 55px;
    }
}

.video-wrapper {
    width: auto;
    margin: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    // padding: 0 25px;
    video {
        width: 100%;
        max-width: 385px;
    }
}


// ----- Media Queries -----
@media only screen and (max-width: 1600px) {
    .training-container {
      margin-left: 10%;
      margin-right: 10%;
    }
  }
  
  @media only screen and (max-width: 1200px) {
    .training-container {
      margin-left: 5%;
      margin-right: 5%;
    }
  }
  
  @media only screen and (max-width: 1085px) {
    .training-container {
      margin-left: 1%;
      margin-right: 1%;
    }
  }