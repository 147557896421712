.card-widget-conatiner-main{
  background-color: #1f2427;
}

.card-widget-main {
  max-width: 1600px;
  display: flex;
  margin: auto;
  padding: 15px 30px 30px;
  p {
    margin-bottom: 0;
  }
}

.card-widget-bg {
  width: 68%;
  padding: 0 90px 0 30px;
  flex-grow: 0;
  flex-shrink: 3;
  flex-basis: 68%;
}

.nav-widget-container {
  position: relative;
  font-size: 0;
  width: 100%;
  height: 510px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
  overflow: hidden;
  margin-bottom: 15px;
}

.nav-widget-container .slide-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.large-flair {
  position: absolute;
  object-fit:contain;
  width: 150px;
  top: 10%;
  @media screen and (max-width: 500px){
    width: 100px;
  }
}



// .slider-nav-circle{
//     height: 15px;
//     width: 15px;
//     padding: 0;
//     margin: 0 5px;
//     border-radius: 50%;
// }

.card-widget-main .slick-dots {
  text-align: left;
  bottom: -15px;
}

.card-widget-main .slick-dots li {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin: 0 5px;
}

.slick-next:before,
.slick-prev:before {
  content: "" !important;
}

.card-widget-main .slick-next,
.card-widget-main .slick-prev {
  border-radius: 50%;
  font-size: 24px;
  line-height: 0px;
  height: 45px;
  width: 45px;
  margin: 0 15px;
  padding: 0;
  background: #fc5310;
  opacity: 0.7;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.slick-next:hover,
.slick-prev:hover {
  opacity: 1;
}

.slick-dots.slick-thumb li.slick-active {
  .slider-nav-circle {
    background: #fc5310;
    opacity: 1;
  }
}

.main-h1-title {
  color: white;
  margin-top: 30px;
}

.font-h1{
  font-size: 34px;
}

.main-p-title {
  color: #F1EBE0;
}

.main-p-title p{
  font-size: 17px;
  line-height: 1.5em;
}

.nav-img-container {
  font-size: 0;
  width: 175px;
  flex: 0 0 175px;
  height: 108px;
  margin-right: 10px;
}

.nav-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  filter: saturate(10%) brightness(0.7);
}

.remove-img-filter img {
  filter: none;
  outline: 2px solid #fc5310;
}

.nav-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
}

.nav-info {
  display: flex;
  // align-items: center;
  margin: 0px 10px;
  margin-bottom: 10px;
  padding: 2.5px 5px;
  cursor: pointer;
  position: relative;
}

.small-flair {
  position: absolute;
  object-fit: contain;
  width: 50px;
  top: 15px;
  left: 5px;
  border: none;
  display: block;
  z-index: 1;
  @media screen and (max-width: 800px){
    display: none;
  }

}

.card-flex-shrink {
  width: 600px;
  flex: 0 2 600px;
  display: flex;
}

.title-width h1 {
  font-size: 20px;
}

@media screen and (max-width: 1250px) {
  .card-widget-bg {
    width: 62%;
    flex-basis: 62%;
  }
}

@media screen and (max-width: 1120px) {
  .card-widget-bg {
    width: 59%;
    flex-basis: 59%;
  }
}

@media screen and (max-width: 1000px) {
  .card-widget-bg {
    width: 57%;
    flex-basis: 57%;
  }
}

@media screen and (max-width: 850px) {
  .card-widget-bg {
    width: 52%;
    flex-basis: 52%;
  }
}

@media screen and (min-width: 800px) {
  .nav-img-container img:hover {
    filter: none;
  }
}

@media screen and (max-width: 800px) {
  .card-widget-main .slick-next,
  .card-widget-main .slick-prev {
    display: none !important;
  }
  .nav-widget-container {
    height: 250px;
  }
  .card-widget-main {
    flex-direction: column;
    padding: 15px;
  }
  .card-widget-bg {
    width: 100%;
    flex-basis: 100%;
    padding: 0;
  }
  .card-widget-main .slick-dots {
    text-align: center;
  }
  .main-tile-box-h1 {
    text-align: center;
  }
  .nav-info {
    position: relative;
    width: 32%;
    margin: 5px 0;
    padding: 0;
  }
  .card-flex-shrink {
    width: 100%;
    flex: 1 1 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 15px;
    // padding: 5px;
  }
  .nav-card-container {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .nav-img-container {
    margin-right: 0;
    width: 100%;
    flex: 0 0 100%;
    height: 73px;
  }
  .nav-img-container img {
    filter: none;
  }
  .title-width {
    position: absolute;
    margin-top: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    text-align: center;
  }
  .title-width h1 {
    font-size: 13px;
    margin-bottom: 0;
  }
  .font-h1{
    font-size: 20px;
  }
  .main-p-title p{
    font-size: 16px;
  }
}

.title-width {
  // width: 230px;
  // flex: 0 0 230px;
}
