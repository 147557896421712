.testimonials-background {
  background-color: #ffffff;
  .testimonials-h1 {
    margin-bottom: 20px;
    font-size: 48px !important;
    padding-bottom: 0;
    letter-spacing: 0.05em !important;
    font-weight: 500 !important;
    line-height: 1.2em;
    padding: 5% 0% 0% 0%;
  }

  .testimonial-body-content {
    padding: 0% 0% 5% 0%;
  }
  .testimonial-h2 {
    color: #ef4a43;
    font-weight: 500 !important;
    line-height: 1.2em;
    font-size: 38px !important;
    padding: 20px 0px;
  }

  .testimonial-widget-container {
    width: 101%;
    padding: 5% 10% 5% 10%;
    background-color: #3f3939;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-color: #7c7c7c;
    margin-bottom: 20px;
  }
  i.fa-quote-right {
    color: #fff;
    display: block;
    margin: 0 auto 15px;
    font-size: 24px;
    border: 3px solid #fff;
    border-radius: 31px;
    width: 25%;
    padding: 4%;
  }

  .testiomonial-widget-content {
    color: #d1d1d1;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5em;
    margin-bottom: 10px;
  }
  .testiominal-image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
  .testimonial-name {
    color: #ef4a43;
    line-height: 1em;
  }
  .testimonial-job {
    color: #ffffff;
    line-height: 1.5em;
    font-size: 0.85em;
  }
  .testiomonial-column-left {
    padding: 0px !important;
    text-align: center;
    position: relative;
    right: 14px;
  }
  .testiomonial-column {
    padding: 0px !important;
    padding-top: 5% !important;
    position: relative;
    left: -26px;
  }
}

@media only screen and (max-width: 640px) {
  .testimonials-background {

    .testimonials-h1 {
      margin-bottom: 20px;
      font-size: 28px !important;
      letter-spacing: 0.05em !important;
    }
    .testimonial-h2 {
      font-size: 20px !important;
      font-family: 'Roboto Condensed', sans-serif !important;
      font-weight: bold !important;
      letter-spacing: .2em !important;
      line-height: 1.2em;
    }

    .testiomonial-column {
      padding: 0px !important;
      padding-top: 5% !important;
      position: relative;
      left: -33px;
    }
  
  }
}
