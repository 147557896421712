.col-image-nx .item {
  width: 20rem;
  height: 20rem;
}

.col-image-nx .imagecontainer {
  position: relative;
  /* text-align: center; */
  color: white;
}

.col-image-nx .text-bottom-left {
  position: absolute;
  // text-transform: uppercase;
  bottom: 8px;
  left: 16px;
}

.col-image-nx .col-img {
  transition: all 500ms;
  height: 100%;
}

.col-image-nx {
  overflow: hidden;
  height: 100%;
  position: relative;
  border-radius: 5px;
}
// .col-image-nx:hover .col-img {
//   transform: scale(1.1);
// }

.col-image-nx:hover .col-text-title {
  opacity: 0;
}

.col-image-nx:hover .col-text-des {
  opacity: 1;
}

.col-image-nx .img-container {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.img-border-radius{
  border-radius: 3px;
}

.col-image-nx .col-text-title {
  position: absolute;
  bottom: 40px;
  left: 20px;
  transition: all ease-in-out 0.5s;
  white-space: pre;
}

.col-image-nx .col-text-title h2 {
  // text-transform: uppercase;
  font-size: 18px !important;
  font-weight: 500;
  color: #fff;
  // letter-spacing: 0.1em !important;
  background: rgba(0,0,0,.3);
    box-shadow: 0 1000px 15px 1025px rgb(0 0 0 / 30%);
    padding: 0 0 18px 0;
}
.col-image-nx .img-overlay {
  width: 100%;
  height: 100%;
  transition: all ease-in-out 0.5s;
  position: absolute;
  // background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 1;
  top: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 5px;
}

.col-image-nx .img-overlay:hover {
  opacity: 1;
}

.col-image-nx .col-text-des {
  padding: 20px;
  text-align: left;
  opacity: 0;
  transition: all ease-in-out 0.5s;
}

.col-image-nx .col-text-des h2 {
  // text-transform: uppercase;
  font-size: 20px !important;
  font-weight: 500;
  color: #fff;
  // letter-spacing: 0.1em !important;
  margin-bottom: 12px;
  white-space: pre;
  background: rgba(0,0,0,.3);
  box-shadow: 0 1000px 15px 1025px rgb(0 0 0 / 30%);
}
.col-image-nx .col-text-des h6 {
  color: #fff;
  font-weight: 400;
  line-height: 1.5rem;
}

@media screen and (min-width: 768px){
  .col-image-nx:hover .col-img {
    transform: scale(1.1);
  }
}

@media only screen and (max-width: 768px) {

  .col-image-nx .img-overlay {
    width: 100%;
    height: 46%;
    position: absolute;
    transition: all ease-in-out 0.5s;
    position: relative;
    background-color: rgb(0 0 0 / 13%);
    cursor: pointer;
    opacity: 0;
    top: -200px;
    display: flex;
  }
  .col-image-nx .col-text-des {
    text-align: center;
}



.col-image-nx .col-text-des h2 {
 
  font-size: 24px !important;
  
}
  .col-image-nx .col-text-title {
    opacity: 0;
  }
  .col-image-nx .col-text-des {
    opacity: 1;
    position: absolute;
    top: 42px;
    padding: 15px;
    box-shadow: 0 1000px 15px 1015px rgb(0 0 0 / 30%);
    background: linear-gradient(to top,rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.3) 100%)!important;
  }
  .col-image-nx .img-overlay {
    opacity: 1;
  }

  .col-image-nx .col-text-des h2 {
    font-weight: 300;
  }
}



@media only screen and (max-width: 580px) {

  .col-image-nx .img-overlay {
    width: 100%;
    height: 46%;
    position: absolute;
    transition: all ease-in-out 0.5s;
    position: relative;
    background-color: rgb(0 0 0 / 13%);
    cursor: pointer;
    opacity: 0;
    top: -197px;
    display: flex;
  }
  .col-image-nx .col-text-des {
    text-align: left;
   
}

  h6 {
    font-size: .9em;
  }



.col-image-nx .col-text-des h2 {
 
  font-size: 24px !important;
  
}
  .col-image-nx .col-text-title {
    opacity: 0;
  }
  .col-image-nx .col-text-des {
    opacity: 1;
    position: absolute;
    top: 6px;
  }
  .col-image-nx .img-overlay {
    opacity: 1;
  }

  .col-image-nx .col-text-des h2 {
    font-weight: 300;
  }
}
