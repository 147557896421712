.n3LaedFormHeader-container {

    img {
        width: 250px;
        height: 67.08px;
    }
    margin: 0%;
    .elementor-widget-container {
        padding: 2%;
    }
}

.elementor-image {
    align-items: center;
    display: flex;
    justify-content: center;
}

.successBackground {
    
    p {
    padding: 35px 45px;
    margin: 0px !important;
    }
    .wpforms-confirmation-container-full {
        background-color: #f2f2f2;

    }
    .elementor-image {
        padding: 0px !important;
    }
    .container {
        padding: 0px !important;
    }
    .app-withoutHeader {
        margin: 0px !important;
    }
}

