.app-mobile-view-rating {
  background-size: cover;
  background-position: top center;
  color: #fff;
  padding: 70px 15px 0;
  .react-multi-carousel-dot--active button {
    background-color: #fff !important;
  }
  .react-multi-carousel-dot button {
    background-color: #b1b1b18f;
  }
  .react-multi-carousel-dot-list {
    bottom: 16px;
  }
  .react-multi-carousel-dot {
    margin-right: 5px;
  }
  .app-rating-card {
    height: 360px;
    width: auto;
    user-select: none;
    text-align: center;
    .app-review-stars-container .app-stars.inactive {
      stroke: #ef424f;
    }
    .app-review-stars-container .app-stars.active {
      color: #ef424f;
      margin-right: 10px;
    }

    .app-review-stars-container .app-stars {
      transform: scale(1.4);
    }
    .app-review-user-details {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      & span {
        font-style: italic;
        font-size: 14px;
        line-height: 1.5;
        &:first-child {
          font-weight: 700;
        }
      }
    }
  }
  .app-review {
    font-size: 1.3em;
    line-height: 1.5;
    height: 220px;
    overflow: hidden;
  }
}
