.app-toast-overlay {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2147483641;
  display: flex;
  justify-content: center;
  .app-toast {
    position: fixed;
    display: flex;
    bottom: 40px;
    user-select: none;
    z-index: 1;
    .toast-body {
      color: #fff;
      font-weight: bold;
    }
  }
}
