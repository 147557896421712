.extend-warranty-cart-container {
    display: flex;
    justify-content: space-between;
}
.item-delete {
    cursor: pointer;
}
.extend-warranty-cart-button{
    padding: 1rem;
    margin-left: 1rem;
    align-self: center;
}
.extend-warranty-cart-text {
    padding-right: 1rem;
}
.extend-warranty-cart-text-description{
    font-weight: bold;
}
.extend-warranty-cart-message-set {
    margin-top: 1rem;
}
.extend-as-product-not-found-container {
    margin-bottom: 4rem;
}