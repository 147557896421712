.nexersys-locationwidget-background {
  padding: 50px 0% 50px 0%;
  background-color: #f7f7f7;
  .maps {
    width: 100%;
    height: 300px;
    border-width: 0px;
  }
  .address {
    display: flex;
    align-items: center;
    justify-content: center;
    .addresscolor {
      a {
        &:hover {
          color: #e0ba67 !important;
        }
        text-decoration: none;
        font-weight: 400;
        color: #e09900 !important;
      }
    }
  }
}
