.app-radio-button-checkout {
  margin-right: 5px;
  .app-outer-circle {
    color: transparent;
    border: 2px solid #67698b;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .app-inner-circle {
      height: 5px;
      width: 5px;
      color: transparent;
      border: 2px solid #67698b;
      border-radius: 50%;
    }
  }
}
