.tshertFormHeader {
    @media (max-width:768px) {
        //padding-top: 45px;
        padding-left: 3px;
    }
    
    h1 {
    font-size: 34px!important;
    font-weight: 400!important;
    letter-spacing: 0!important;
    margin-bottom: 0rem !important;
    }
    span {
        font-size: 20px;
        font-weight: 400;
        color: black;   
    }
    b, strong {
       
        font-size: 20px;
    font-weight: 400;
    color: #000;
    }
    p {
        margin-bottom: 0 !important;
    }

}


.form-instructions {
    font-size: 20px;
    font-weight: 400;
    color: black;
}

.tshirtForm-Discription {
    @media (max-width:768px) {
        padding-bottom: 16px ;
    }
    
  
}