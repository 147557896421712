model-viewer {
    width: 100%;
    height: 50vh;
    position: relative;
}

#ar-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    bottom: 15px;
    // padding: 0px 16px 0px 40px;
    font-family: Roboto Regular, Helvetica Neue, sans-serif;
    font-size: 14px;
    color:#000;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    border: 1px solid #DADCE0;
    &:active {
        background-color: #E8EAED;
    }
    &:focus {
        outline: none;
    }
    &:focus-visible {
        outline: 1px solid #4285f4;
    }
}

i {
    padding-right: 7px;
    padding-left: 0;
    margin-left: 0;
}

