.product-carouselWidget {
  .carousel-size {
    height: 500px;
    width: auto;
    .carousel-indicators {
      bottom: -56px !important;
    }
  }
  .pro-carouselWidget-image-align {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .carousel-indicators .active {
    background-color: red !important;
  }

  .img-size {
    height: 500px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (max-width: 768px) {
    .img-size {
      height: 400px;
    }
  }

  .arrow {
    color: red;
    font-size: 25px;
  }

  .carousel-indicators li {
    width: 15px !important;
    height: 15px !important;
    margin-right: 6px;
    border-radius: 100%;
    cursor: pointer;
    background-color: #b1b1b1 !important;
    background-clip: padding-box;
    border: none !important;
    margin-right: 6px !important;
    opacity: 1 !important;
    transition: opacity 0.6s ease;
  }

  .carousel-indicators {
    bottom: -42px !important;
  }

  @media only screen and (max-width: 426px) {
    .img-size {
      width: 280px;
    }
    .carousel-size {
      height: 405px;
    }
  }

  .btncarousel-widget-arrow-right {
    right: calc(4% + 1px);
  }

  .btncarousel-widget-arrow-left {
    left: calc(4% + 1px);
  }
  .btncarousel-widget {
    position: absolute;
    outline: 0;
    transition: all 0.5s;
    border-radius: 35px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
  }
}
@media only screen and (max-width: 990px) {
  .product-carouselWidget {
    padding: 30px 0px;
  }
}
