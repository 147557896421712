.app-comment {
  margin: 30px 0px 0px;
  .app-comment-header {
    display: flex;
    .app-comment-user-image {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      margin-right: 20px;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .app-comment-user-details {
      display: flex;
      flex-direction: column;
      a.app-user-name {
        color: #e09900;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          color: #e0ba67;
        }
      }
      .app-user-name {
        font-weight: bold;
      }
      .app-user-date {
        font-size: 85%;
        color: #595959;
        a {
          color: #595959;
          text-decoration: none;
          &:hover {
            color: #007bff;
          }
        }
      }
    }
  }
  .app-comment-body {
    padding: 30px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin-top: 1.5em;
    line-height: 1.5em !important;
    color: #666;
    .app-reply {
      font-size: 85%;
      line-height: 1.25em;
      color: #e09900;
      cursor: pointer;
      user-select: none;
      &:hover {
        color: #e0ba67;
      }
    }
  }
}

.app-comment.reply {
  margin: 0;
  margin-left: 25px;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  padding: 24px 0 24px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
