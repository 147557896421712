.productVideoContent-background {
  padding: 5% 0% 5% 0%;
  background-color: #f9f9f9;
}

.productVideo-video {
  border-radius: 40px;
  max-width: 100%;
  width: 100%;
  margin: 0;
  line-height: 1;
  border: none;
  width: 446px;
}

.productVideoContent-flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.productVideoContent-h2 {
  font-size: 38px !important;
  font-weight: 500 !important;
  line-height: 1.2em;
  padding: 0px;
}

.productVideoContent-h6 {
  font-size: 16px !important;
  line-height: 1.5em !important;
  color: #666;
  font-weight: 400;
  text-align: center;
}

.productVideo-flex-correct {
  display: flex;
  justify-content: center;
  flex-direction: row;
  text-align: center;
}

.productVideo-flex-correct-h6 {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 1.5em !important;
  color: #666;
  padding: 10px;
}

.productVideoContent-img {
  width: 15%;
  height: auto;
  padding-right: 10px;
  min-width: 25px;
}

.productVideoContent-Button {
  margin-top: 50px;
  background-color: #de0000;
  text-align: right;
  margin-right: 0;
  border-radius: 40px;
  color: #fff;
  padding: 13px 30px;
  font-size: 18px;
}

.productVideoContent-Button:hover {
  box-shadow: none;
  text-decoration: none;
  color: #fff;
}

@media only screen and (max-width: 780px) {
  .productVideo-flex-correct {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }


.productVideoContent-img {
    width: 25px;
    height: auto;
    padding-right: 10px;
   
  }

  .productVideoContent-h2 {
    font-size: 20px !important;
   margin-bottom: 20px;
  }
}
