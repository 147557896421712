.productbadge-techCard-widgte-background {
  padding: 60px 60px 80px 60px;
  background-color: #f2f2f2;
}

.productfeaturebadge-img {
  max-width: 92px;
  margin-bottom: 20px;
}

.productbadge-flex-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.productbadge-flex-container h4 {
  color: black;
  font-size: 24px !important;
  padding-bottom: 10px;
  font-weight: 500 !important;
  line-height: 1.2em;
}
.productbadge-flex-container h6 {
    letter-spacing: 0em !important;
  font-size: 16px !important;
  line-height: 1.5em !important;
  color: #666;
  font-weight: 400;
}

@media only screen and (max-width: 525px) {
  .productbadge-techCard-widgte-background {
    padding: 20px 0px 80px 0px;
  }

  .productbadge-flex-container h4 {
    
    font-size: 16px !important;
  
}
}



