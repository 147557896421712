.Bundle-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2147483640;
  // overflow-y: auto;
  .Bundle-container {
    min-width: 300px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .product-details-container {
      position: relative;
      overflow-y: auto;
      padding: 6% 0% 0% 0%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 90%;
      height: 100%;
      background-color: #fff;
      .row {
        margin: 0;
      }
      .app-image-section {
        padding: 0px 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .app-image-main {
          height: 350px;
          width: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          margin-bottom: 10px;
        }
        p {
          width: 70%;
          text-align: center;
        }
        @media only screen and (max-width: 768px) {
          p {
            width: 100%;
          }
          // .app-image-main {
          //   width: 90%;
          // }
        }
        .app-sub-img-container {
          display: flex;
          margin: 0px 0px 20px;
          .app-sub-image {
            width: 80px;
            cursor: pointer;
            height: 80px;
            border: 1px solid black;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            margin-left: 5px;
          }
          @media only screen and (max-width: 768px) {
            .app-sub-image {
              width: 60px;
              height: 60px;
            }
          }
        }
      }
      .app-desc-section {
        padding: 0px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .selected-items {
          font-weight: bold;
          margin: 10px 0px;
        }
        form {
          width: 100%;
        }
        .form-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 55%;
          margin: auto;
          .form-control {
            width: 50%;
            margin: auto;
            color: #666666;
            font-size: 18px;
            margin-top: 5px;
          }

          @media screen and (max-width: 768px) {
            .form-control {
              width: 70%;
            }
          }

          @media screen and (max-width: 468px) {
            .form-control {
              width: 100%;
            }
          }

          .form-control-wrapper {
            width: 100%;
            border: 1px solid rgba(0,0,0,.1);
            margin-bottom: 15px;
          }

          .clear-select{
            margin-top: 1rem;
            font-size: 12px;
            text-align: center;
            margin-bottom: 0;
            font-weight: 400;
          }

          .clear-select-disabled{
            color: grey;
          }

          .clear-select-enabled{
            color: #e0ba67;
            cursor: pointer;
          }
          
          option {
            font-size: 18px;
          }

          .choice-of-product {
            width: 50%;
            margin: 10px 0px;
          }
          .optional-prod-container {
            margin: 10px 0px;
            width: 100%;
            display: flex;
            justify-content: center;
          }
        }
        h2 {
          .old-price {
            font-size: 18px;
            text-decoration: line-through;
            color: black;
          }
          .new-price {
            color: #de0000;
            margin-left: 5px;
          }
        }
        h3 {
          color: #666;
          text-align: center;
        }
        h6 {
          color: #de0000;
        }
        .app-cart-btn {
          background-color: #de0000;
          text-align: center;
          padding: 10px 20px;
          border: none;
          outline: none;
          width: 150px;
          text-transform: capitalize;
          color: #fff;
          border-radius: 30px;
          white-space: nowrap;
          cursor: pointer;
          margin: 10px 0px;
        }
        .app-note-section {
          font-size: 10px;
          text-align: center;
          margin: 20px 0px;
          width: 50%;
        }
      }
    }
    .bundle-close-icon {
      z-index: 1;
      color: white;
      font-size: 18px;
      font-weight: 600;
      position: absolute;
      right: 20px;
      top: 0px;
      cursor: pointer;
    }
  }
}

.overlay-conatiner{
  background-color: white;
}

.overlay-wrapper {
  width: 1250px;
  height: auto;
  max-width: 100vw;
    max-height: 100vh;
    padding: 0;
    position: relative;
    overflow: auto;
}

// .element-product-descp-wrapper{
//   padding: 40px;
// }

.product-descp-row{
  display: flex;
  padding: 40px;
}

.product-descp-col {
  width: 50%;
  padding: 10px;
}

.product-desp-image-conatiner img {
  width: 100%;
  max-height: 500px;
  object-fit: contain;
  margin-bottom: 0;
}

.product-desp-info-conatiner h2{
  font-size: 34px;
    font-weight: 300;
    margin-bottom: 20px;
}

.product-desp-info-conatiner h3{
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.product-desp-info-conatiner .normal-price {
  font-size: 20px;
  text-decoration: line-through;
}

.product-desp-info-conatiner .price-conatiner{
  line-height: 1.25;
  margin-bottom: 20px;
}

.discounted-price{
  color: #d3222a;
  display: flex;
  align-items: center;
}

.discounted-price span:first-child{
  font-size: 34px;
}

.discounted-price .free-shipping{
    margin-left: .5em;
}

.fighter-price-container {
  display: flex;
  flex-direction: column;
}

.fighter-price-container span:first-child{
  font-size: 34px;
  color: black;
}

.fighter-price-container .free-shipping{
  color: #666;
  font-size: 12px;
  line-height: 1.5em;
}

.elite-align-col-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.elite-align-col-center .product-desp-info-conatiner {
  width: 100%;
  text-align: center;
}

.price-conatiner .money-saved{
  font-size: 20px;
  // display: none;
  color: #d3222a;
}

.divider-wrapper-elite {
  text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    width: 100%;
}

.divider-wrapper-elite div {
  width: 33%;
    border-top: 1px solid black;
}

.elite-align-col-center .divider-wrapper-elite div {
  margin: auto;
}

.product-descp-col .div-feature-includes-wrapper h4{
  text-align: left;
}

.product-descp-col .div-feature-includes-wrapper ul {
  padding-left: 0;
  margin-bottom: 0;
}

.product-descp-col .div-feature-includes li {
  justify-content: flex-start;
}

.product-descp-col .product-elite-button-wrapper {
  margin-bottom: 5px;
}

.product-descp-col .div-feature-includes-wrapper {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.elite-align-col-center .div-feature-includes li {
  justify-content: center;
}

.elite-align-col-center .div-feature-includes-wrapper h4{
  text-align: center;
} 

.n3-bundle-item .affirm-as-low-as a::after {
  font-size: 12px !important;
  color: #d3222a !important;
}

.elite-fqa-wrapper .card {
  border: 0;
}

.elite-fqa-wrapper .card .show {
  max-height: 300px;
  overflow-y: auto;
}

.elite-fqa-wrapper .card .according-body {
  border: 0;
  padding: 15px 30px;
}

.elite-fqa-wrapper .card .according-header {
  border-bottom: 0;
  padding: 15px 30px;
}

.slider-elite-sub-nav {
  display: flex;
  justify-content: center;
  max-height: 75px;
  padding: 0 15px;
}

.sub-nav-img-container{
  max-width: 75px;
  display: flex !important;
  // margin: auto;
  width: 300px;
  margin: 5px;
  padding: 2px;
  border-radius: 2px;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 30%);
  cursor: pointer;
}

.slider-elite-sub-nav .active{
  border: 1px solid #D3222A;
}

.sub-nav-img-container img{
  max-width: 100%;
  max-height: 75px;
}

.product-desp-image-conatiner p {
  font-size: 12px!important;
  text-align: center;
  margin: 1.6em !important;
  margin-top: 0!important;
  padding: 0 2em;
}

.additional-links-wrapper{
  padding: 10px 0 20px 0;
  border-top: 1px solid #d4d4d4;
}


.additional-links-conatiner{
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
}

.additional-links-conatiner .data-addlink{
  width: 25%;
  padding: 10px;
  text-align: center;
}

.data-addlink a {
  color: black;
  text-decoration: underline !important;
  cursor: pointer;
}

@media (max-width: 688px){
  .product-descp-row{
    flex-wrap: wrap;
  }
  .product-descp-col {
    width: 100%;
  }
  .product-desp-info-conatiner{
    text-align: center;
  }
  .divider-wrapper-elite div {
    margin: auto;
}
.product-descp-col .div-feature-includes-wrapper h4{
  text-align: center;
}
.product-descp-col .div-feature-includes li {
  justify-content: center;
}
.product-desp-info-conatiner h2 {
  margin-bottom: 10px;
}
.product-desp-info-conatiner h3 {
  font-weight: 400;
margin-bottom: 10px;
}
.discounted-price {
  justify-content: center;
}
}

@media screen and (max-width : 979px) {
  .additional-links-conatiner{
    max-width: 1024x;
    flex-wrap: wrap;
  } 
}

@media (max-width: 688px){
  .additional-links-conatiner .data-addlink{
    width: 50%;
  }
}

@media screen and (max-width : 480px) {
  .additional-links-conatiner{
    max-width: 797x;
  }
  
}