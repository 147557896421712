.container-fluid .download-container-right {
  align-content: center;
  align-items: center;
  text-align: center;
}
.container-fluid .download-container-left {
  align-content: center;
  align-items: center;
  text-align: center;
}

.container-fluid .downloadsection-container {
  height: auto;
  margin-left: 20%;
  margin-right: 20%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.downloadsection-container {
  @media (min-width: 768px){
    .row-cols-md-2>* {
        flex: 0 0 auto;
        width: 100%;
    }
  } 
}

.container-fluid .img-mobile-download {
  width: 300px;
  height: 550px;
  @media only screen and (max-width: 350px) {
    width: 100%;
  }
}

.container-fluid .img-social-download {
  width: 32px;
  height: 33px;
  margin-left: 10px;
}

.container-fluid .image-social-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.container-fluid .image-social-container a {
  padding-left: 15px;
}

.container-fluid .h6-downloadsection-content {
  letter-spacing: 0.01em !important;
  font-size: 16px;
  line-height: 1.5em !important;
  color: #666;
  font-weight: 400;
  font-family: inherit;
  padding: 10px;
}

.container-fluid .h6-downloadsection-content strong {
  font-weight: 500;
}

.container-fluid .h2-downloadsection-content {
  text-transform: uppercase;
  letter-spacing: 0.1em !important;
  font-weight: 300 !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 38px;
  padding-bottom: 10px;
  padding-top: 20px;
}

.container-fluid .img-app-link {
  width: 150px;
  height: 51px;
  padding-left: 15px;
}

.container-fluid .image-app-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

@media only screen and (max-width: 980px) {
  .h2-downloadsection-content {
    font-size: 24px !important;
    margin-top: 25px;
  }

  .h6-downloadsection-content {
    font-size: 16px !important;
    margin-top: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .h6-downloadsection-content {
    font-size: 14px !important;
    margin-top: 0px;
  }

  .downloadsection-background-mobile .container-fluid {
    background-color: transparent;
    background-image: linear-gradient(180deg, #ffffff 0%, #e2e8ea 100%);
  }
}

.container-fluid .flex-box {
  display: flex;
  justify-content: center;
}

.flex-box div {
  height: 40px;
  width: 30%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@media only screen and (max-width: 1600px) {
  .container-fluid .downloadsection-container {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media only screen and (max-width: 1200px) {
  .container-fluid .downloadsection-container {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media only screen and (max-width: 1085px) {
  .container-fluid .downloadsection-container {
    margin-left: 1%;
    margin-right: 1%;
  }
}

.desktop-view-download-section {
  display: none;
}

.mobile-view-download-section {
  display: none;
}

@media only screen and (max-width: 768px) {
  .mobile-view-download-section {
    display: block;
  }
}

@media only screen and (min-width: 769px) {
  .desktop-view-download-section {
    display: block;
  }
}
