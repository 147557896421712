.ManualHeader-Background{
    padding: 1% 0% 0% 0%;
    .ManualHeader-h1{
        padding-top: 50px;
        padding-bottom: 1%;
        font-size: 48px !important;
        padding-bottom: 0;
        letter-spacing: 0.05em !important;
        font-weight: 500 !important;
         line-height: 1.2em;
         text-align: center;
         color: #333;
    }
}



@media only screen and (max-width: 640px) {
    .ManualHeader-Background{
        
        .ManualHeader-h1{
            padding-top: 20px;
            font-size: 28px !important;
        }
    }
    
  }
  