.app-loader {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2147483641;
  display: flex;
  align-items: center;
  justify-content: center;
  &.loading {
    background-color: rgb(255, 255, 255);
  }
}
