.app-search-box {
  display: flex;
  .app-input-box {
    margin-right: 5px;
    position: relative;
    input {
      height: 49px;
    }
    .app-input-clear {
      z-index: 1;
      position: absolute;
      top: 33%;
      right: 10px;
      line-height: 8px;
      cursor: pointer;
    }
  }

  button {
    height: 49px;
    &.btn {
      padding: 0.375rem 1.75rem;
    }
  }
}
