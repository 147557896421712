.app-cart-slider-container {
  height: 100%;
  .app-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0.5;
    z-index: 9999;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
  .app-cart-slider {
    height: 100%;
    width: 300px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10000;
    transition: all 0.5s;
    background-color: white;
    &.app-cart-slider-hidden {
      right: -350px;
    }
    .app-cart-slider-header {
      background-color: #f2f2f2;
      color: #333333;
      border-bottom-width: 1px;
      border-bottom-color: #eeeeee;
      border-bottom-style: solid;
      padding: 10px 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & span:first-child {
        font-size: 20px;
        font-weight: 600;
      }
      & span:nth-child(2) {
        cursor: pointer;
      }
    }
    .app-cart-body-overlay {
      width: 100%;
      z-index: 999;
      position: absolute;
      height: 100%;
      background-color: #f0f0f0;
      opacity: 0.7;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .app-cart-slider-body {
      position: relative;
      overflow-y: auto;
      .app-cart-no-items {
        padding: 10px;
      }
      .app-single-item {
        border-bottom: 1px solid #eeeeee;
        padding: 10px;
        .app-cart-item-i-t {
          display: flex;
          .item-delete {
            cursor: pointer;
          }
          a {
            width: 30%;
            .item-image {
              height: 80px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: top;
              width: 100%;
            }
          }
          .item-image {
            height: 80px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: top;
            width: 30%;
          }
          .item-title {
            width: 60%;
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            .app-item-data {
              font-size: 14px;
              a {
                text-decoration: none;
                color: black;
              }
            }
          }
        }
        .quantity-input {
          justify-content: center;
          margin-top: 15px;
          input {
            width: 53px;
          }
        }
        .app-cart-item-price {
          text-align: end;
          margin: 10px 0px;
          font-weight: 600;
        }

        .app-bundle-selected {
          display: flex;
          flex-direction: column;
          margin: 10px 0px;
          font-size: 13px;
          a {
            text-decoration: none;
            color: black;
          }
        }
      }
    }
    .app-cart-slider-footer {
      background-color: #f2f2f2;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 10px;
      .white-glove-wrapper{
        text-align: center;
      }
      .white-glove-wrapper h6{
        font-size: 16px;
        line-height: 1.5em;
        color: #666;
        margin-bottom: 0;
        }
      .assembly-info{
        cursor: pointer;
        margin-bottom: 10px !important;
      }
      .white-glove-wrapper a{
        color: #de0000;
        font-weight: bold;
        text-transform: uppercase;
        }
      .app-footer-note {
        font-size: 12px;
        text-align: center;
      }
      .app-footer-price-section {
        display: flex;
        justify-content: space-between;
        & span:first-child {
          text-transform: capitalize;
        }
        & span {
          font-weight: bold;
        }
      }
      a {
        text-decoration: none;
        .app-footer-btn {
          margin: 5px 0px;
          background-color: #de0000;
          color: #fff;
          padding-top: 10px;
          padding-bottom: 10px;
          width: 100%;
          text-align: center;
          cursor: pointer;
        }
      }

      .app-footer-coupon-section {
        margin: 20px 0px 10px;
        .footer-coupon-disable {
          text-align: center;
          cursor: pointer;
          color: #e09900;
          font-size: 16px;
          margin: 0;
          height: 45px;
          &:hover {
            color: #e0ba67;
          }
        }
        .footer-text-box {
          flex-grow: 4;
          border-radius: 2px;
          text-align: left;
          font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
          font-size: 16px;
          font-weight: 400;
          width: 74%;
          color: #666666;
          background-color: #fafafa;
          border-color: #cccccc;
          border: 1px solid #ccc;
          padding: 9px 15px;
          &:focus {
            outline: none;
          }
        }

        .footer-button {
          flex-grow: 2;
          margin-left: 5px;
          padding: 11px 10px;
          background: rgb(224, 3, 3);
          border-radius: 0px;
          border-style: solid;
          border-color: rgb(224, 3, 3);
          border-width: 3px;
          color: rgb(255, 255, 255);
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 1;
          white-space: normal;
          text-align: center;
          word-break: break-word;
          align-self: flex-end;
          cursor: pointer;
        }
      }
    }
  }
}

