.app-header {
  background-color: #231e1f;
  height: 60px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  .Logo {
    box-sizing: border-box;
    display: inline-block;
    height: 100%;
    cursor: pointer;
  }

  .Logo img {
    padding-left: 12px;
    width: 152px;
    padding-top: 4px;
  }

  .Logo-header{
    display: flex;
    align-items: center;
    padding-left: 35px;
  }
  
  .Logo-header img{
    max-width: 120px;
  }

  .Logo .logo-container {
    display: flex;
    align-items: center;
    height: 100%;
    align-items: center;
  }
}
.menu-items {
  height: auto;
  width: 100%;
  background-color: #231e1f;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 59px;
  padding: 0;
  z-index: 999;
  .item {
    color: #fff;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    font-size: 15px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 60px;
    &.active {
      background-color: #3f3f3f;
    }
  }
  a {
    text-decoration: none;
  }
}

.headerback .nav {
  height: 100%;
}

.prod-items{
  display: flex;
  justify-content: space-between;
  align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.prod-items-a{
  color: #fff;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    font-size: 14px;
    // padding-left: 20px;
    // padding-right: 20px;
    line-height: 40px;
}

.prod-items-a:hover{
  color: white;
  background-color: #4f4f4f;
}

.prod-nav:hover{
  background-color: #3f3f3f;
}

.prod-products-open{
  background-color: #3f3f3f;
}

.active-sub-link{
  background-color: #545252;
}

.nav-shop-now{
  margin: auto;
  margin-right: 0;
  padding-right: 15px;
}

.header-2{
  height: 71px;
}

.shop-now-button{
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.87px;
  text-transform: uppercase;
  text-decoration: none;
  padding: .4em 1em;
  background: #fc5310;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,.6);
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  background-position: center;
}

.shop-now-button:hover{
  color: white;
}

@media (max-width: 480px){
  .shop-now-button{
    font-size: 14px;
  }
}