.app-recent-post {
  padding-top: 3.5rem;
  @media only screen and (max-width: 992px) {
    h2 {
      font-size: 20px;
      letter-spacing: 0.2em;
      font-weight: 400;
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
    li {
      padding: 0px 0px 10px 0px;

      a:hover {
        color: #e0ba67;
      }
      a {
        font-size: 17px;
        color: #e09900;
        text-decoration: none;
      }
      @media only screen and (max-width: 992px) {
        a {
          font-size: 15px;
        }
      }
    }
  }
}
