.productOverview {
  .product-overview-col {
    padding: 0px !important;
  }
  .product-overview-right {
    height: 100%;
    min-height: 500px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .product-container-left {
    padding: 20% 15% 20% 15%;
  }
  .product-overview-flex-left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .product-overview-flex-left img {
    height: 50px;
    object-fit: contain;
    // margin-bottom: 0.75rem;
  }

  .product-overview-long-title img {
    width: 350px;
    max-width: 100%;
  }

  .product-pro-home {
    text-align: center;
    color: #ffffff;
    font-size: 34px;
    text-transform: uppercase;
    line-height: 1em;
  }

  .prod-learn-more {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
    a {
      text-decoration: none;
    }
    i {
      color: white;
      margin-right: 10px;
    }
    span {
      color: white;
      font-size: 1.2em;
      text-decoration: underline;
      margin-right: 5px;
    }
  }
  .bounce-2 {
    animation-name: bounce-2;
    animation-timing-function: ease-in-out;
  }
  @keyframes bounce-2 {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .product-overview-price {
    font-size: 2.5em;
    // margin: 0.5em 0.5rem 0 0.5rem;
    margin: 0.5em 0 0 0;
    color: #ffffff;
  }

  .product-overview-shipping {
    color: white;
    margin-bottom: 0.75rem;
  }

  .product-overview-financing {
    font-size: 0.9em;
    // margin: 0.25em 0.5rem 0.35em;
    margin: 0.25em 0 0;
    text-decoration: underline;
    color: #ffffff;
  }

  .multiple-prod-productdetail-affirm {
    .affirm-as-low-as a::after {
      content: "Financing Available: Low Monthly Payments" !important;
      font-size: 14px;
      line-height: 1 !important;
      padding: 0.25em 0;
      color: #fff !important;
    }
  }

  .product-overview-belowtext {
    font-size: 1.1em;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 0.75rem;
  }

  .button-show-now {
    border-width: 0px !important;
    // width: 196px;
    width: auto;
    display: inline-block;
    background-color: #de0000;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.2em;
    padding: 10px 30px;
    margin-right: 0;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0.75rem;
    white-space: nowrap;
    margin-top: 0.75rem;
  }

  .product-overview-reviews-popup {
    color: white;
    text-decoration: underline;
    cursor: pointer;
    padding-left: 5px;
  }
  .productoverview-star {
    color: red;
    transform: scale(1.2);
    margin-right: 5px;
  }

  .productoverview-popstar {
    padding: 10px 0;
    margin-top: 10px;
    margin-bottom: 0.75rem;
  }

  @media screen and( max-width:990px ) {
    .product-overview-right {
      height: 100%;
      min-height: 400px;
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  @media (max-width: 979px) {
    .product-overview-flex-left img {
      height: 35px;
    }
    .product-container-left {
      padding: 5%;
    }
  }

  @media (max-width: 480px) {
    .product-pro-home {
      text-align: left;
    }
    .product-container-left {
      padding: 35px 15px 35px 20px;
    }
    .product-overview-flex-left {
      text-align: left;
    }
    .prod-learn-more {
      justify-content: flex-start;
    }
  }
}

