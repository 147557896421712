.DownloadButton-flex{
    display: flex;
    width: 100%;
   justify-content: center;
   flex-direction: column;
   padding: 5% 0% 5% 0%;
   text-align: center;
    .DownloadButton-flex-h4{
        text-align: center !important;
        font-size: 24px !important;
        padding-bottom: 10px;
        margin-bottom: 25px;
       
      
    }
    .DownloadButton-watch-download{
        fill: #ef4a43;
    color: #ef4a43;
    background-color: rgba(0,0,0,0);
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #ef4a43;
    border-radius: 40px 40px 40px 40px;
    font-size: 16px;
    padding: 15px 30px;
    margin-bottom: 20px;
    }
    .DownloadButton-watch-download:hover{
       text-decoration: none;
    }
}