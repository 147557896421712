.quantity-input {
  display: flex;
  align-items: center;
  width: 250px;
  .quantity-button {
    width: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    margin: 0px 10px;
    border: 1px solid #ced4da;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
    width: 100px;
    text-align: center;
  }
}
