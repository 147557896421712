.militarybackground {
  padding: 5% 0%;
  .military-h2 {
    font-size: 38px !important;
    font-weight: 500 !important;
    line-height: 1.2em;
    box-sizing: border-box;
  }
  img {
    max-width: 169px;
  }
  .militarycol {
    text-align: -webkit-center;
    align-self: center;
  }
  a {
    color: #e09900 !important;
  }
  h6{
    font-size: 16px !important;
    font-family: Roboto, 'Oswald', sans-serif !important;
    line-height: 1.5em !important;
    color: #666;
    text-align: center;
  }
}

@media only screen and (max-width: 900px) {
  .militarybackground {
    padding: 5% 2%;
    .military-h2 {
      font-size: 20px !important;
      text-align: center;
      font-family: 'Roboto Condensed', sans-serif !important;
      font-weight: bold !important;
      letter-spacing: .2em !important;
      line-height: 1.2em;
      padding-bottom: 20px;
    }
    img {
        max-width: 100px;
        padding: 2% 0%;
      }
  }
 
}
