.app-type-list {
  font-weight: 400;
  line-height: 1.5em;
  margin-bottom: 5px;
  font-size: 85%;
  color: #595959;
  a {
    color: #595959 !important;
    text-decoration: none;
  }
  a:hover {
    color: #007bff !important;
  }
  i {
    margin-right: 5px;
  }
}
