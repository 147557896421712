.carousel-indicators .active {
  background-color: red !important;
}

.carouselimage-size {
  padding: 10% 5% 10% 5%;
  position: relative;
  .react-multi-carousel-list {
    position: unset;
  }
  .custom-dot-list-style {
    bottom: 40px;
  }
  @media only screen and (max-width: 768px) {
    .custom-dot-list-style {
      bottom: 10px;
    }
  }
}

.carousel-size {
  height: auto;
  width: auto;
}

.carouselimage-img {
  width: 100%;
  height: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 15px 0px;
}

.arrow {
  color: red;
  font-size: 25px;
}

.carouselimage-container {
  width: 100%;
  height: 100%;
}

.carouselimage-size .carousel-indicators {
  bottom: -42px !important;
}

.carouselimage-flex-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  .carouselimage-h2-widget-title {
    color: black;
    font-family: Roboto !important;
    font-weight: 300 !important;
    font-size: 27px !important;
    letter-spacing: 1.5px !important;
    text-transform: uppercase !important;
  }
}

.carouselimage-flex-content h6 {
  color: #666;
  font-size: 14px;
  line-height: 1.5em;
}

.carouselimage-flex-content h2 {
  color: black;
  font-family: Roboto !important;
  font-weight: 300 !important;
  font-size: 27px !important;
  letter-spacing: 1.5px !important;
  text-transform: uppercase !important;
}

.carouselimage-right-content {
  max-height: 440px;
}
.display-carouselimg {
  display: flex !important;
  justify-content: center !important;
}

@media only screen and (max-width: 700px) {
  .carouselimage-img {
    height: 250px;
    background-size: contain;
    background-repeat: no-repeat;
    align-content: center;
  }

  .display-carouselimg {
    display: flex !important;
    justify-content: center !important;
  }
}

@media only screen and (max-width: 580px) {
  .carouselimage-flex-content {
    .carouselimage-h2-widget-title {
      display: block;
      text-transform: capitalize !important;
      font-size: 27px !important;
      color: #333333;
      font-weight: 300;
    }
  }
  .carouselimage-img {
    height: 168px;
    background-size: contain;
    background-repeat: no-repeat;
    align-content: center;
  }
}

.row-item {
  margin-left: 10%;
  margin-right: 10%;
}
