.imagecontainer {
    width: 150px;
    height: 189px;
    // padding: 10px 30px;

    img {
        width: 100%;
        height: 100%;
    }
}
.checkboxitem {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    margin: 10px 30px;
}

.formlabel {
    font-size: 20px!important;
    font-weight: 400!important;
    margin: 20px 0 10px!important;

    @media (max-width:767px) {
        text-align: center;
        width: 100%;
        margin: 20px 0 !important;
    }
    @media (max-width: 660px){
    font-size: 17px!important;
    font-weight: 700!important;
    margin: 25px 0 0!important;
    text-align: justify;
    line-height: 1.3;
   }
}

.emailformlabel {
    font-size: 20px!important;
    font-weight: 400!important;
    margin: 0 0 10px!important;

    @media (max-width:767px) {
        text-align: center;
        width: 100%;
        margin: 20px 0 !important;
    }
    @media (max-width: 660px){
    font-size: 17px!important;
    font-weight: 700!important;
    margin: 0px 0 0!important;
    text-align: justify;
    line-height: 1.3;
   }
}

.N3LeadFormAccordion-body {
    padding: 20px 30px;
    background-color: #f2f2f2;
}

input[type='radio'] {
    padding: 0 !important;
}

.inputdimension {
    @media (min-width:768px) {
        max-width: 60%;
    }
   
}

.cssbutton {
    border-radius: 60px;
    background-color: #de0000!important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px!important;
    font-weight: 500;
    padding: 10px 60px!important;
    cursor: pointer;
    outline: none !important;
    color: #fff!important;
    border: none;
    @media (max-width:768px) {
        padding: 0;
    }

}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 35px;
}
.error-message-astrid-show {
    color: red !important;
    margin-left: 5px !important;
  }

//   .container {
//       @media (max-width:768px) {
//           padding: 0!important;
//       }
//   }


  .selectinput {
      max-width: 60% !important;
      @media (max-width: 660px)  {
        max-width: 100% !important;
    }

  }
  .selectlabel {
      margin: 0px 0px 10px !important;
  }

  .customformdiv {
      margin-bottom: 0px !important;
      input[type='radio'] {
        padding: 0 !important;
      }  
  }
  .customradio {
      padding: 0px !important;
      label {
       
            font-size: 15px !important;
        
      }
  }

  .cssfeedback {
    .invalid-feedback {
        display: block !important;
        // width: 100%;
        // margin-top: .25rem;
        // font-size: .875em;
        // color: #dc3545;
    }

  }

  .form-check-input.is-invalid~.form-check-label, .was-validated .form-check-input:invalid~.form-check-label {
    color: #212529 !important;
  
    
}
.is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip, .was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip {
    display: block !important;
}

.form-check.is-invalid~.invalid-feedback{
    display: block !important;
}

.error-radio-message {
    color: #dc3545;
    display: block;
    font-size: 13px;
}

