.tshirtFormContent {
    max-width: 407px;
    margin: auto;
    
    
    margin: auto;
    h3 {
        text-align: center;
        word-wrap: break-word;
        color: #000;
        font-size: 34px!important;
        margin-bottom: 25px;
        letter-spacing: 0!important;
        @media (max-width: 750px) {
            font-size: 26px!important;
        }

    }
    p {
        text-align: center;
        font-weight: 500;
        line-height: 1.5;
        color: #212529;
    }
}

.confirmation-thanks {
    h3 {
        text-align: center;
    }
}

.scuccessConfirmation-background {
    padding: 60px;
    @media (max-width: 750px) {
        padding: 40px;
    }

}