.TrainingVideoHeader-Background{
    padding: 5% 0% 0% 0%;
    text-align: center;
    .TrainingVideoHeader-h1{
        font-size: 48px !important;
        padding-bottom: 0;
        letter-spacing: 0.05em !important;
        font-weight: 500 !important;
        line-height: 1.2em;
        color: #333333;
    }
    .TraingingVideoHeader-hr{
        margin: 0% 0% 03% 0%;
        padding: 0% 0% 03% 0%;
        border-style: solid;
        border-width: 0px 0px 02px 0px;
        border-radius: 0px 0px 0px 0px;
        color: #333333;
    }
    
}


@media only screen and (max-width: 640px) {
    .TrainingVideoHeader-Background{
     
        .TrainingVideoHeader-h1{
            font-size: 28px !important;
        }
        
        
    }
    
  }
  
  