.carousel-size {
  height: auto;
  width: auto;
}

.carousel-indicators {
  bottom: -60px !important;
}

.rating-carousel-container {
  position: relative;
  .react-multi-carousel-list {
    position: unset !important;
  }
  .custom-dot-list-style {
    bottom: -42px !important;
  }
  .react-multi-carousel-item {
    padding: 0px 8px;
  }


  .slick-dots li button{
    width: 15px !important;
    height: 15px !important;
    margin-right: 6px;
    border-radius: 100%;
    cursor: pointer;
    background-color: #b1b1b1;
    background-clip: padding-box;
    border: none !important;
    margin-right: 6px !important;
    opacity: 1 !important;
    transition: opacity 0.6s ease;
  }

  .slick-dots li.slick-active button:before {
    width: 15px !important;
    height: 15px !important;
    margin-right: 6px;
    border-radius: 100%;
    cursor: pointer;
    background-color: red !important;
    background-clip: padding-box;
    border: none !important;
    margin-right: 6px !important;
    opacity: 1 !important;
    transition: opacity 0.6s ease;
    
  }

  .slick-dots li button:before {
      font-family: none;
       font-size: none;
       font-size:0px;
  }

  .slick-dots {
   
    bottom: -41px;
   
}

.card-carousel-each-card{
  padding: 8px 8px 8px 8px;
}
  

}

.card-rating-footer {
  // overflow: auto;
  padding-bottom: 15px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0px;
}

.testimonial__name {
  font-weight: 700;
  color: #333333;
  font-style: italic;
}

.testimonial__title {
  color: #333333;
  font-style: italic;
}

.card-rating-star-img {
  max-width: 150px;
  display: block;
  margin: 0 auto 15px;
}

.card-rating-body {
  background-size: cover;
  background-position: center center;
  line-height: 1.5;
  text-align: center;
  padding: 32px;
  overflow: auto;
}

.card-rating-body-text {
  height: 88%;
  // overflow: auto;
  background-size: cover;
  background-position: center center;
  color: #ffffff;
  font-size: 18px;
  font-style: normal;
  line-height: 1.4;
  text-align: center;
  &::-webkit-scrollbar {
    width: 5px;
  }
}

.whole-card-rating {
  background: #fff;
  box-shadow: 0 3px 6px #00000029;
  height: 366px;
  border-radius: 0px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.card-desk-staring {
  padding: 10px;
  display: flex;
  justify-content: center;
}
.carousal-star {
  color: white;
  transform: scale(1.6);
  margin-right: 15px;
  margin-bottom: 10px;
}
