.prod-overview-conatiner {
  .product-detail-col {
    padding: 0px !important;
    height: 600px;
  }
  .product-detail-padding {
    padding: 40px !important;
  }
  .product-detail-container {
    padding: 10px;
  }

  .product-detail-img {
    height: 500px;
    width: auto;
  }

  .product-detail-flex {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .product-detail-flex-left {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding-top: 30px;
    .title-image {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 40px;
      width: 350px;
      margin: 20px 0px;
    }
    @media only screen and (max-width: 768px) {
      .title-image {
        width: 85%;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .product-detail-flex-left {
      padding-top: 0px;
    }
  }

  .product-detail-flex-left img {
    max-width: 150px;
    margin-bottom: 0.75rem;
  }

  .product-detail-price-h3 {
    font-weight: 500 !important;
    line-height: 1.2em;
    font-size: 1.75rem;
    margin: 0px;
    color: black;
  }

  .product-detail-financing-h6 {
    font-weight: 400;
    margin: 0.25em 0.5rem 0.35em;
    color: black;
  }

  .button-show-now {
    border-width: 0px !important;
    display: inline-block;  
    background-color: #de0000;
    text-align: right;
    border-radius: 60px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.2em;
    padding: 7px 30px;
    margin-right: 0;
    text-align: center;
    text-transform: uppercase;
  }

  .prod-display-title-mobile {
    font-weight: 300;
  }
  .prod-display-title-desktop {
    font-weight: normal;
  }

  .product-detail-reviews-popup {
    color: black;
    text-decoration: underline;
    cursor: pointer;
    padding-left: 10px;
    font-weight: 500;
    font-size: 1.2em;
  }
  .productdetail-star {
    color: red;
    width: 20px;
    height: 20px;
    margin-right: 2px;
  }

  .productdetail-popstar {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 0.75rem;
  }

  @media only screen and (max-width: 990px) {
    .product-detail-img {
      height: 350px;
      width: auto;
    }

    .product-detail-col {
      padding: 0px !important;
      height: auto;
    }
  }

  @media only screen and (max-width: 990px) {
    .product-detail-padding {
      padding: 0px !important;
    }
  }
}
