.militiry-discount {
  // background-image: url("https://web.cdn.nexersys.com/assets/Military_Light_Desktop_f6b925507f.jpg");
  max-width: 1600px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 40vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.militiry-discount-landing-wrapper {
  background-color: black;
}

.militiry-discount-wrapper {
  background-color: white;
}

.military-info {
  width: 35%;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
}

.military-info-white {
  color: white;
}

.learn-more-title {
  color: #666;
  text-decoration: underline;
}

.learn-more-title:focus {
  color: #666;
}

.learn-more-title-white {
  color: white;
}

.military-title {
  margin-bottom: 1rem;
}

.military-title-300 {
  font-weight: 300;
}

.military-title p {
  margin-bottom: 0;
}

.elite-exp-container {
  background-color: #e2e2e2;
}

.elite-exp-wrapper {
  max-width: 1200px;
  padding: 10px;
  color: black;
  margin: auto;
}

.elite-exp-wrapper h2 {
  font-size: 34px;
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 0;
  /* margin: auto; */
  /* align-items: center; */
  text-align: center;
}

.prod-elite-bundles-container {
  padding: 30px 0;
  background-color: #e2e2e2;
}

.prod-elite-bundles-wrapper {
  max-width: 850px;
  margin: auto;
}

.landing-bundles-row {
  display: flex;
  width: 100%;
}

.landing-bundles-col {
  display: flex;
  width: 50%;
  padding: 15px 30px;
}

.product-exp-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.elite-bestseller {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.elite-bestseller div {
  border-radius: 12.5px 12.5px 0 0;
}

.elite-bestseller img {
  height: auto;
  max-width: 100%;
}

.elite-bg-highlight {
  border: 2px solid rgba(211, 34, 42, 0.5);
}

.product-exp-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding-bottom: 30px;
  box-shadow: 0 4px 10px 2px rgb(0 0 0 / 50%);
}

.product-elite-title-wrapper {
  margin: 30px 0 5px;
}

.product-elite-title-wrapper h3 {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.product-elite-image-wrapper {
  width: 100%;
  height: 400px;
}

.product-elite-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-elite-price-wrapper {
  text-align: center;
  line-height: 1.25;
  margin-bottom: 20px;
}

.elite-actual-price {
  font-size: 13px;
  color: #bababa;
}

.elite-discounted-price {
  position: relative;
  font-size: 23px;
  font-weight: 800;
  text-transform: uppercase;
}

.elite-discounted-price .accent {
  color: #fc5310;
}

.actualprice-padding {
  padding: 26px 0 0;
}

.product-elite-button-wrapper {
  margin-bottom: 20px;
}

.product-elite-button-wrapper button {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.87px;
  text-transform: uppercase;
  padding: 0.6em 1em;
  background: #fc5310;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  background-position: center;
}

@media (min-width: 1200px) {
  .military-title {
    font-size: 2.2rem;
  }
}

@media (min-width: 1600px) {
  .militiry-discount {
    height: 35vw;
  }
}

@media (max-width: 979px) {
  .militiry-discount {
    height: 85vw;
  }
  .landing-bundles-row {
    flex-wrap: wrap;
  }
  .product-exp-container {
    max-width: 1024px;
    width: 100%;
  }
  .landing-bundles-col {
    width: 100%;
  }
  .product-elite-image-wrapper {
    height: 100%;
  }
  .elite-exp-container {
    border-bottom: 2px solid #c2c2c2;
  }
}

@media (max-width: 960px) {
  .militiry-discount {
    align-items: flex-end;
    justify-content: center;
  }

  .military-title {
    font-size: 22px;
    max-width: 330px;
    text-align: center;
  }

  .military-info {
    width: 100%;
    align-items: center;
    // padding: 30px 40px;
  }

  .military-sub-title {
    display: none;
  }
}

@media (max-width: 780px) {
  .landing-2-divider {
    border: 2px solid white;
  }
}

@media (max-width: 480px) {
  .militiry-discount {
    height: 110vw;
  }
  .landing-bundles-col {
    padding: 10px;
  }
}
