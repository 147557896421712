.white-gove-assembly-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000CC;
    position: fixed;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1999999999999999999;
    .app-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: black;
        opacity: 0.5;
        z-index: 1;
        height: 100%;
        width: 100%;
        overflow-y: auto;
      }
}

.white-gove-assembly-wrapper{
    background-color: white;
    z-index: 2;
}

.white-gove-assembly-wrapper .info-container{
    width: 650px;
    height: auto;
    max-width: 100vw;
    max-height: 100vh;
    padding: 0;
    overflow: auto;
    display: flex;
    background-color: #FFFFFF;
    // border-style: solid;
    // border-color: #000000;
    position: relative;
    // border-radius: 10px 10px 10px 10px;
    .assembly-loader {
        width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    // border-radius: 10px 10px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.error-div {
    width: 100%;
    position: absolute;
    top: 1rem;
    color: red;
    font-weight: 500;
    text-align: center;
}
.bundle-close-icon {
    z-index: 1;
    color: white;
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    right: 20px;
    top: 0px;
    cursor: pointer;
  }
}

.white-glove-info {
    p {
        margin-bottom: 0;
    }
}

.assembly-info-wrapper{
    margin-top: 60px;
    margin-bottom: 60px;
    width: 100%;
    padding: 10px;
}

.assembly-element-col {
    max-width: 1200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.asselbly-title-container {
    margin: 0px 0px 15px 7.5px;
}

.asselbly-title-container h6 {
    color: #333333;
    font-size: 28px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 20px;
    line-height: 1;
}

.assembly-law-info {
    margin-bottom: 20px;
    padding: 0px 20px 0px 20px;
}

.assembly-law-info p{
    color: #D3222A;
    text-align: center;
    margin-bottom: 0;
}

.assembly-message-wrapper{
    padding: 0px 20px 0px 20px;
    margin-bottom: 20px;
}

.assembly-message-wrapper p {
    font-size: 16px;
    text-align: center;
    margin-bottom: 0;
}

.assembly-price-Wrapper {
    margin: 10px 0;
    // line-height: 1.5em;
}

.assembly-price-Wrapper .price-border {
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
    border: 1.5px solid black;
    border-radius: 3px;
    padding: 5px 10px;
    color: black;
    margin-bottom: 20px;
}

@media (max-width: 979px){
    .white-gove-assembly-wrapper .info-container {
        width: 94vw;
    }
}
