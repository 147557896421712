.nav-tabs {
  border-bottom: 1px solid #d4d4d4;
  width: 100%;
  position: relative;
  text-transform: uppercase;
}

.nav {
  flex-wrap: nowrap !important;
}

.tab-content {
  margin: 20px 20px 20px 20px;
}

.scroll-bar {
  overflow-x: hidden;
  height: 550px;
  box-shadow: 0 2px 4px rgba(41, 41, 43, 0.289798) !important;
}

.nav-tabs .nav-link {
  padding: 20px 25px;
}

.scroll-bar a {
  color: #474747 !important;
}

.according-body {
  padding: 10px;
  border: 1px solid #d4d4d4;
}

.according-header {
  background-color: white !important;
  cursor: pointer;
  line-height: 1;
  margin-bottom: 0px !important;
  padding: 12px 15px;
  border: 1px solid #d4d4d4;
}

.details-header{
  padding: 15px 30px;
}

.details-header div {
  margin-bottom: 1.5em;
  color: #666;
  font-weight: 400;
}