.background-group-color-carousal {
  padding: 60px 0px;
  background-color: #f2f2f2;
}

.cardCarouselRating-h1{
  font-size: 38px !important;
  padding-bottom: 30px;
  padding-top: 10px;
  letter-spacing: 0.2em !important;
  text-transform: uppercase;
  font-family: 'Roboto', Arial, sans-serif !important;
  text-align: center;
  font-weight: 300 !important;
}



.cardgroup-img-hidden{
  display:none;
}
.cardgroup-img-show{
  display:block;
}


@media only screen and (max-width: 525px) {
  .background-group-color-carousal {
    padding: 60px 0px 80px 0px;
  }
}
