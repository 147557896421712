$enable-validation-icons: false;
.returnFormHeader {
    padding: 10px;
    margin-top: 30px;
    h1 {
        font-size: 34px!important;
        font-weight: 300!important;
        line-height: 1.25!important;
    }
    span {
        font-size: 16px!important;
        font-weight: 300!important;
        line-height: 1.5em!important;
        color: #666 !important;
    }
}

.returnElementMaxWidth {
    max-width: 60%;

    @media (max-width:625px) {
        max-width: 100% !important;

    }
}
.returnElementMaxWidth25 {
    max-width: 28%;
    @media (max-width:625px) {
        max-width: 100% !important;

    }
}

.returnradioOtionContainer {
    display: flex;
    flex-wrap: wrap;

    .radioInputContet {
        max-width: 150px;
        margin: 5px;

    }
    .radioInputContet img {
        max-width: 100%;
    }

} 

.returnRequestButton {
    padding: 10px 15px !important;
    margin-top: 25px;
}


.form-control.is-invalid, .was-validated .form-control:invalid {
    border: 1px solid #cc0000 !important;
    border-radius: 0px !important;
}

.invalid-feedback {
    color: #cc0000 !important;
    font-size: 13px;
}

.error-radio-message {
    color: #cc0000 !important;
   
}