.TrainingVideo-background{
    padding: 05% 0% 5% 0%;
    .TrainingVideo-title{
        padding-bottom: 20px;
    }
    .TrainingVideo-videoframe{
        width:100%;
        height:229px;
    }
}




@media only screen and (max-width: 640px) {
    .TrainingVideo-background{
     
        .TrainingVideo-title{
            font-size: 20px !important;
        }

        .TrainingVideo-videoframe{
            width:100%;
            height:200px;
        }
    }
    
    
  }
  