.app-search-card {
  margin: 20px 0px;
  h2 {
    padding-bottom: 10px;
    a {
      color: #e09900;
      text-decoration: none;
      font-size: 38px;
    }
    a:hover {
      color: #e0ba67;
    }
    @media only screen and (max-width: 992px) {
      a {
        font-size: 20px !important;
        letter-spacing: 0.2em;
        font-weight: 400;
      }
    }
  }
  .app-search-card-summary {
    margin-top: 2em;
    p {
      line-height: 1.5em;
      color: #666;
    }
    a {
      color: #e09900;
      text-decoration: none;
    }
    a:hover {
      color: #e0ba67;
    }
  }
}
