.HeroFormAccordionGroup-background {
    padding: 0px;
    margin: 20px 0px;
}
.heroFormMaxWidth {
    max-width: 60%;
    @media (max-width:768px) {
        max-width: 100%;
    }
}
.groupElementMaxWidth {
    max-width: 60%;
    @media (max-width:768px) {
        max-width: 100%;
    }

}
.mainContainer {
    padding: 0px !important;   
}


.HeroesFormWidget-content {
    h2 {
        font-size: 34px!important;
        font-weight: 300!important;
        line-height: 1.25!important;
        font-weight: 400;
    }
    @media (max-width: 480px) {
h2 {
    font-size: 28px!important;
    font-weight: 300!important;
}
    }
}
.HeroFormHeader-container {
    margin-top: 30px;
    width: 100%;

    @media (max-width: 768px) {
    padding: 10px;
    text-align: justify;

    }
}

.elementor-heading-title {
    padding: 0;
    margin: 0;
    line-height: 1;
    font-size: 20px!important;
    font-weight: 500!important;
    @media (max-width: 480px){
    font-size: 20px!important;
    font-weight: 400!important;
    }
}

.body-text-primary {
    font-size: 16px!important;
    font-weight: 300!important;
    margin-bottom: 1em;
    line-height: 1.5em!important;
    color: #666;
} 

b, strong {
    font-weight: bolder;
    font-size: 16px !important;
    margin-bottom: 1em;
    line-height: 1.5em !important;
    color: #666;
}

.elementor-widget:not(:last-child) {
    margin-bottom: 20px !important;
}


.heroButton {
    padding: 10px 15px !important;
}

.elementor-widget-container {
    p:last-child {
        margin-bottom: 0px !important;
    }
}

.HeroFormAccordion-body {
    padding: 2% 2%;
    background-color: #f7f7f7;
  }

  .herosuccessBackground {
      background-color: #f7f7f7;


  }
  .herosuccessmessage {
      p {
          padding: 15px;
          background: #e0ffc7;
          border: 1px solid #b4d39b;
      }

  }

  .wpforms-confirmation-container-full {
    padding: 15px !important; 
}