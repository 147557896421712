.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}


.strip-cardElement {
  .CardBrandIcon-container {
    right: 0px;
  }
}

.label-color{
  color: #666 !important;
  font-weight: bold;
}
.label-color::after{
  content: "*";
  color: red;
}
.strip-integration-label{
  line-height: 1.5em !important;
  color: #666;
  font-weight: 400;
  margin-bottom: 1.5em;
}