.product-card-container {
    background-color: black;
    margin-top: -1%;
}


.product-res {
    text-align: center;
}

.product-res img {
    flex: 1 1 100%;
    max-height: 165px;
    max-width: 100%;
    object-fit: contain;
}