.video-new-content-caption {
  color: #fc5310;
  text-align: center;
  padding-top: 10px
}

.media-widget-contatiner {
  .mobile-des {
    display: none;
    text-align: center;
    overflow: hidden;
    color: #666;
    background-color: #f4f5f5;
    line-height: 1.5em !important;
    margin: 0px 10px;
    padding: 4px;
    p {
      //margin-bottom: 0;
    }
  }
  .row {
    width: 100%;
    height: 600px;
    padding: 30px 20%;
    margin: 0px;
    .media-col-one {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h1 {
        text-align: center;
        font-weight: 300;
        font-size: 2.375rem;
        letter-spacing: 5px;
        margin-bottom: 5px;
        text-transform: uppercase;
      }
      h2 {
        font-size: 1.25rem;
        font-weight: 300;
        letter-spacing: 5px;
        color: #e00303;
        font-weight: 400;
        text-transform: uppercase;
      }
      @media only screen and(max-width: 444px) {
        h2 {
          text-align: center;
        }
      }
      @media screen and (max-width: 992px) {
        h1 {
          text-transform: none;
          font-size: 27px;
          letter-spacing: 1px;
        }
        h2 {
          text-transform: capitalize;
        }
      }
      @media screen and (max-width: 992px) {
        .button-text-container {
          margin: 0px !important;
        }
      }

      .button-text-container {
        width: 100%;
        margin: 25px 0px 0px 0px;

        .button-container {
          margin: 25px 0px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          button {
            text-transform: uppercase;
            padding: 10px 40px;
            width: 50%;
            font-size: 1rem;
            background: #f9f9f9;
            color: #333;
            border: 2px solid #cdcdcd;
            box-shadow: 0 0 8px #cdcdcd;
            font-weight: 600;
            letter-spacing: 0.2em;
            &.active {
              color: #de0000;
              border-color: #de0000;
              box-shadow: 0 0 8px #de0000;
            }
          }
          @media only screen and(max-width: 444px) {
            button {
              font-size: 0.7rem;
              padding: 10px 20px;
            }
          }

          #btn-1 {
            border-radius: 40px 0 0 40px;
            border-right: none;
            height: 48px;
            background-size: cover;
            background-position: center center;
          }
          #btn-2 {
            border-radius: 0px 40px 40px 0;
            border-left: none;
            height: 48px;
            background-size: cover;
            background-position: center center;
          }
          .divider {
            display: inline-block;
            border-left: 2px solid #cdcdcd;
            height: 60px;
            vertical-align: middle;
            position: relative;
            top: 0px;
          }
        }
        .des {
          text-align: center;
          min-height: 145px;
          overflow: hidden;
          color: #666;
          line-height: 1.5em !important;
        }
      }
    }
    .media-col-two {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .media-video-container {
        height: 100%;
      }
      @media only screen and (max-width: 992px) {
      }
    }
  }
  .mb-title {
    display: none;
    @media screen and (max-width: 480px) {
      display: inline;
    }
  }
  @media only screen and (max-width: 1510px) {
    .row {
      padding: 30px 10%;
    }
  }
  @media only screen and (max-width: 992px) {
    .row {
      height: auto;
      .media-col-two {
        padding-top: 30px;
        height: 500px;
      }
    }
    .des {
      display: none;
    }
    .mobile-des {
      display: block;
      margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 444px) {
    .row {
      .media-col-two {
        padding-top: 30px;
        height: 400px;
      }
    }
  }
  @media only screen and (max-width: 1135px) {
    .row {
      padding: 30px 5%;
    }
  }
  @media only screen and (max-width: 1014px) {
    .row {
      padding: 30px 5%;
      .media-col-one {
        h2 {
          font-size: 1rem !important;
        }
      }
    }
  }
}

.media-widget-contatiner-video {
  background-color: #23272d;
  .media-widget-wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }
  .row {
    height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .button-text-container {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  button {
    &.active {
      color: #fc5310 !important;
      border-color: #fc5310 !important;
      box-shadow: 0 0 8px #fc5310 !important;
    }
  }
  #btn-1,
  #btn-2 {
    height: 35px !important;
    padding: 0 !important;
    // width: 33%;
    width: 31%;
    min-width: 100px;
  }
  // #btn-2 {
  //   height: 35px !important;
  //   padding: 0 !important;
  // }
  .divider {
    height: 35px !important;
  }
  .video-title {
    font-size: 34px;
    font-weight: 500;
    line-height: 1.25;
    margin-bottom: 20px;
    text-align: center;
  }
  .video-title p {
    margin-bottom: 0;
    color: white;
  }
}

.landing-video-wrapper video {
  height: 580px;
  width: 480px;
  align-self: center;
}

.landing-animation-info-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .video-title {
    font-size: 34px;
    font-weight: 500;
    line-height: 1.25;
    text-align: center;
  }
}

@media only screen and (max-width: 980px) {
  .landing-video-wrapper video {
    height: 437px;
    width: 350px;
  }
}

@media (min-width: 481px) and (max-width: 979px) {
  .media-widget-contatiner-video .media-widget-wrapper {
    max-width: 1024px;
  }
  .hide-animation-tablet {
    display: none;
  }
  .landing-animation-info-component {
    padding: 30px;
    padding-top: 60px;
  }
}

@media only screen and (max-width: 480px) {
  .media-widget-contatiner-video .media-widget-wrapper {
    max-width: 767px;
  }
  .landing-video-wrapper video {
    height: auto;
  }
  .landing-animation-info-component .video-title {
    display: none;
  }
  // .hide-animation-tablet {
  //   padding-top: 20px;
  // }
  .media-widget-contatiner-video {
    #btn-1,
    #btn-2 {
      width: 48%;
    }
  }
  .media-widget-contatiner-video .button-text-container {
    flex-direction: column-reverse;
  }
  // .media-widget-contatiner-video .button-container {
  //   // margin-bottom: 10px !important;
  // }
}
