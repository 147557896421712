.ProductUseageCard-background{
    padding: 5% 0% 5% 0%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .ProductUseageCard-img {
    height: auto;
    max-width: 100%;
    border: none;
    margin-bottom: 15px;
    box-shadow: none;
  }

  .btncarousel-widget-arrow-right {
    right: calc(-20px + 1px);
    bottom: calc(45% + 1px);
  }
  .btncarousel-widget-arrow-left {
    left: calc(-20px + 1px);
    top: calc(43% + 1px);
    z-index: 99;
  }

  .btncarousel-widget {
    position: absolute;
    outline: 0;
    transition: all 0.5s;
    border-radius: 35px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
    font-size: 35px;
  }

  
  .ProductUseageCard-flex-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .ProductUseageCard-number-img{
    max-width: 50px;
    margin-bottom: 15px;
  }

  
  .ProductUseageCard-h2{
    font-size: 38px ;
    padding-bottom: 10px;
    font-weight: 500 !important;
    line-height: 1.2em;
    text-align: center;
  }

  .ProductUseageCard-h4{
    font-size: 24px !important;
    
    font-weight: 500 !important;
    line-height: 1.2em;
    text-align: center;
  }

  .ProductUseageCard-h6{
    font-size: 16px !important;
    line-height: 1.5em !important;
    color: #666;
    text-align: center;
  }
  
  
  @media only screen and (max-width: 578px) {
    .ProductUseageCard-background{
      padding: 60px 0px 80px 0px;
    }
    .ProductUseageCard-h2{
      font-size: 20px ;
     
    }

    .ProductUseageCard-h4 {
      font-size: 16px !important;
    }
  }

 