.NavigationGroup {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  height: 100%;
  .flex-search-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 100%;
    cursor: pointer;
    &:hover {
      background-color: #3f3f3f;
    }
    &.hide {
      opacity: 0;
    }
    &.show {
      opacity: 1;
    }
  }

  ol{
    
      margin-top: 0 !important;
      margin-bottom: 1rem !important;
      display: contents !important;
      list-style-type: decimal;
      margin-block-start: 0 !important;
      margin-block-end: 0 !important;
     margin-inline-start: 0px !important;
      margin-inline-end: 0px !important;
     padding-inline-start: 40px !important; 
      }
  .flex-cart-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 100%;
    cursor: pointer;
    &:hover {
      background-color: #3f3f3f;
    }
  }

  .flex-menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 100%;
    cursor: pointer;
    color: #fff;
    span {
      padding-left: 6px;
      font-size: 14px;
    }
    &:active {
      background-color: #54595f;
    }
  }

  .flex-clear-search {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    &:active {
      background-color: #54595f;
    }
  }

  input {
    width: 350px;
    height: 60px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #3f3f3f;
    opacity: 0.9;
    border: transparent;
    color: white;
    padding-left: 20px;
    padding-right: 60px;
    &:focus {
      outline: none;
    }
  }

  .NavigationItem {
    margin: 0;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    cursor: pointer;
    align-items: center;
    padding: 10px 15px 10px 15px;
    letter-spacing: 0.1em;
  }
  .NavigationItem {
    color: white;
    text-decoration: none;
    display: flex;
    text-transform: uppercase;
    font-size: 15px;
    &.active {
      background-color: #545252;
    }
  }

  .NavigationItem:hover,
  .NavigationItem:active {
    /* border-bottom: 4px solid blue; */
    color: white;
    text-decoration: none;
  }

  .NavigationItem:hover {
    background-color: #3f3f3f;
  }
}

.products-dropdown{
  height: 100%;
  position: relative;
  &:hover {
    background-color: #3f3f3f;
  }
}


.prod-sub-dropdown{
  width: 200px;
  position: absolute;
  background-color: #3f3f3f;
  list-style: none;
  padding: 0;
}


.NavigationItem-a {
  margin: 0;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  cursor: pointer;
  align-items: center;
  padding: 10px 15px 10px 15px;
  letter-spacing: 0.1em;
}
.NavigationItem-a {
  color: white;
  text-decoration: none;
  display: flex;
  text-transform: uppercase;
  font-size: 14px;
  &.active {
    background-color: #3f3f3f;
  }
}

.NavigationItem-a:hover,
.NavigationItem-a:active {
  /* border-bottom: 4px solid blue; */
  color: white;
  text-decoration: none;
}

.NavigationItem-a:hover {
  background-color: #4f4f4f;
}

.arrow-downxs{
  padding: 0 10px;
}